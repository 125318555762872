import { API_MAIN_URL, LOCAL_STORAGE_TOKEN } from '../shared/consts'

export const questionService = {
    signOut,
    postQuestionForm,
    getPreviousQuestion,
    verifyAllQuestions
};

//Please pass as parameter the id of the current question and the id of the answer which the user selected (not the next_question_id)


function postQuestionForm(object) {


     let token = localStorage.getItem(LOCAL_STORAGE_TOKEN)
    token = token.replace(/['"]+/g, '')
    const bearerToken = `Bearer ${token}` 

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': bearerToken },
        body: object
    };

    return fetch(`${API_MAIN_URL}/api/user/questions/v2/form`, requestOptions)
        .then(handleResponse)
        .then(data => {

            console.log(data)

            return data;
        });
}
//No parameter necessary
function getPreviousQuestion() {

    let path = window.location.pathname
    const currentLang = path.substring(0,3);
    let langSign = currentLang.split("/")[1]
    const upperCaseLangSign = langSign.toUpperCase()

     let token = localStorage.getItem(LOCAL_STORAGE_TOKEN)
    token = token.replace(/['"]+/g, '')
    const bearerToken = `Bearer ${token}` 

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': bearerToken
        },

    };

    return fetch(`${API_MAIN_URL}/api/user/questions/previous?language=${upperCaseLangSign}`, requestOptions)
        .then(handleResponse)
        .then(data => {

            console.log(data)

            return data;
        });
}

// No parameters necessary
function verifyAllQuestions(id_of_question, id_of_answer) {

     let token = localStorage.getItem(LOCAL_STORAGE_TOKEN)
    token = token.replace(/['"]+/g, '')
    const bearerToken = `Bearer ${token}` 

    const body = {id_of_question, id_of_answer}

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': bearerToken
        },
        body: JSON.stringify({ id_of_question, id_of_answer })

    };

    return fetch(`${API_MAIN_URL}/api/user/questions/verify`, requestOptions)
        .then(handleResponse)
        .then(data => {

            console.log(data)

            return data;
        });
}

function signOut() {

    const token = localStorage.getItem(LOCAL_STORAGE_TOKEN)

    const bearerToken = `Bearer ${token}` 

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': bearerToken
        }
    };

    return fetch(`${API_MAIN_URL}/api/user/logout`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // remove user from local storage to log user out
            localStorage.removeItem(LOCAL_STORAGE_TOKEN);

            return user;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //signOut();

                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
