import * as actionTypes from '../actionTypes';
import {authService} from '../../../services/index'
import {useHistory} from 'react-router-dom'


const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

const authFail = (error_code) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error_code
    };
};

const authSuccess = (token) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token
    };
};


const logOutSuccuess = () => {
    return {
        type: actionTypes.AUTH_SIGNOUT
    };
};

const resetPasswordSuccess = () => {
    return {
        type: actionTypes.RESET_PASSWORD
    };
};


const confirmResetPasswordSuccess = () => {

    return {
        type: actionTypes.CONFIRM_PASSWORD
    };
}

//const authRedirect = (path) => {
//     return {
//         type: actionTypes.SET_AUTH_REDIRECT_PATH,
//         redirect_path: path
//     };
// };


const questionSuccess = (form) => {
    return {
        type: actionTypes.QUESTION_SUCCESS,
        form        
    };
};

const tokenSuccess = (form) => {
    return {
        type: actionTypes.TOKEN_SUCCESS,
        form        
    };
};

const questionFail = (error_code) => {
    return {
        type: actionTypes.QUESTION_FAIL,
        error_code
    };
};

const resetPasswordFail = (error_code) => {
    return {
        type: actionTypes.RESET_PASSWORD_FAIL,
        error_code
    };
};

const confirmPasswordFail = (error_code) => {
    return {
        type: actionTypes.CONFIRM_PASSWORD_FAIL,
        error_code
    };
};

const confirmLoginSuccess = () => {
    return {
        type: actionTypes.CONFIRM_LOGIN
    };
};

export  function removeAuthErrorState() {
    return {
        type: actionTypes.REMOVE_ERROR_STATE
    };
};


export  function removeAuthError() {
    return dispatch => {
        dispatch(removeAuthErrorState());

    };
}


export  function confirmLogin() {
    return dispatch => {
        dispatch(confirmLoginSuccess());

    };
}


    export  function signIn(email, password) {
        return dispatch => {
            dispatch(authStart());
    
            authService.signIn(email, password)
                .then(
                    data => {

                        dispatch(authSuccess(data.token));

                        //history.push('/');
                    },
                    error => {
                        dispatch(authFail(error));
                    }
                );
        };
    
    }


    export  function signUp(name, email, password) {
        return dispatch => {
            dispatch(authStart({ email }));
    
            authService.signUp(name, email, password)
                .then(
                    data => { 

                        dispatch(authSuccess(data.token));

                    },
                    error => {
                        dispatch(authFail(error));
                    }
                );
        };
    }


    export  function signOut() {
        return dispatch => {
            dispatch(authStart());
    
            authService.signOut()
                .then(
                    data => { 
                        dispatch(logOutSuccuess());

                    },
                    error => {
                        dispatch(authFail(error));
                    }
                );
        };
    }


    export  function sendResetPasswordMail(mail) {
        return dispatch => {
    
            authService.sendResetPasswordMail(mail)
                .then(
                    data => { 

                        dispatch(resetPasswordSuccess())
                    },
                    error => {
                        dispatch(resetPasswordFail(error));
                    }
                );
        };
    }

    export  function confirmResetPassword(token, password) {


        return dispatch => {
            
    
            authService.confirmResetPassword(token, password)
                .then(
                    data => { 

                        dispatch(confirmResetPasswordSuccess())
                    },
                    error => {
                        dispatch(confirmPasswordFail(error));
                    }
                );
        };
    }


    export  function validateAuthenticationAndGetData() {
        return dispatch => {

            authService.validateAuthenticationAndGetData()
                .then(
                    data => { 

                        if(data.form){
                            dispatch(questionSuccess(data.form))
                        }else{
                            dispatch(questionFail(data.error))
                        }
                                        },
                    error => {
                        dispatch(questionFail(error));
                    }
                );
        };
    }


    export  function validateTokenAndGetData(id) {
        return dispatch => {

            authService.validateTokenAndGetData(id)
                .then(
                    data => { 

                        console.log("ACTION FORM ==>  " + data.form)

                        if(data.form){
                            dispatch(tokenSuccess(data.form))
                        }else{
                            dispatch(questionFail(data.error))
                        }
                                        },
                    error => {
                        dispatch(questionFail(error));
                    }
                );
        };
    }