import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export const page18 = (pageData) => {
        let state = pageData.state
        const { t, i18n, classes } = pageData.props;

        
        let back_btn = t('common_btn.back')
        let next_btn = t('common_btn.next')
        let error_msg_detail = t('error_msg.error_msg_detail')
        let label_page_title = t('question.page_18.page_title')
        let label_main_travel_destination = t('question.page_18.main_travel_destination')
        let label_number_of_entries_requested = t('question.page_18.number_of_entries_requested')

        if(state.page18.destination && !state.page18.destination.label)state.page18.destination = JSON.parse(state.page18.destination)
        if(state.page18.numberOfEntries && !state.page18.numberOfEntries.label)state.page18.numberOfEntries = JSON.parse(state.page18.numberOfEntries)


        let numberOfEntriesRequested_options = t('numberOfEntriesRequested', {returnObjects:true})

        

        return(
            <>
                <p className="question-title">{label_page_title}</p>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>{label_main_travel_destination}</Form.Label>
                            <Select
                                onChange={pageData.handleChangeSelect('page18','destination')}
                                value={state.page18.destination} 
                                options={state.stateListOptions}
                            />
                            {state.errors.includes('error_page18_destination') &&
                                <p className="error_class">{error_msg_detail}</p>
                            }
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>{label_number_of_entries_requested}</Form.Label>
                            <Select
                                onChange={pageData.handleChangeSelect('page18','numberOfEntries')}
                                value={state.page18.numberOfEntries} 
                                options={numberOfEntriesRequested_options}
                            />
                            {state.errors.includes('error_page18_numberOfEntries') &&
                                <p className="error_class">{error_msg_detail}</p>
                            }
                        </Form.Group>
                    </Form.Row>
                    <Row className="question_btn">
                        <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={()=>pageData.prevPage('page17','page18')} >{back_btn}</Button></Col>
                        <Col sm></Col>
                        <Col sm></Col>
                        <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={()=>pageData.nextPage('page18','page19')} >{next_btn}</Button></Col>
                    </Row>
                </Form>
            </>
        )
    }