import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import DateFnsUtils from '@date-io/date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export const page2 = (pageData) => {
    let state = pageData.state
    let currentDate = new Date()

    const { t, i18n, classes } = pageData.props;

    let back_btn = t('common_btn.back')
    let next_btn = t('common_btn.next')
    let label_page_title = t('question.page_2.page_title')
    let label_date_of_birth = t('question.page_2.date_of_birth')
    let label_place_of_birth = t('question.page_2.place_of_birth')
    let label_country_of_birth = t('question.page_2.country_of_birth')
    let error_msg_detail = t('error_msg.error_msg_detail')



    // let json = {hello: "hello", bye: "bye"}

    // console.log(json)
    // console.log(JSON.stringify(json))


    console.log("state.page2.dateOfBirth ==> " + state.page2.dateOfBirth)


    if(state.page2.dateOfBirth)state.page2.dateOfBirth = new Date(state.page2.dateOfBirth)

    console.log(state.page2.countryOfBirth)
    if(state.page2.countryOfBirth && !state.page2.countryOfBirth.label)state.page2.countryOfBirth = JSON.parse(state.page2.countryOfBirth)




    return (
            <>
            <p className="question-title">{label_page_title}</p>
            <Form>
                <Form.Row className="customedate">
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>{label_date_of_birth}</Form.Label>
                        <DatePicker
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            maxDate={currentDate}
                            selected={state.page2.dateOfBirth}
                            onSelect={pageData.handleChangeCalendar('page2', 'dateOfBirth')} //when day is clicked
                            onChange={pageData.handleChangeCalendar('page2', 'dateOfBirth')} //only when value has changed
                        />
                        {state.errors.includes('error_page2_dateOfBirth') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_place_of_birth}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('page2', 'placeOfBirth')}
                            value={state.page2.placeOfBirth}
                        />
                        {state.errors.includes('error_page2_placeOfBirth') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>

                <Form.Group controlId="formGridAddress1">
                    <Form.Label>{label_country_of_birth}</Form.Label>
                    <Select
                        value={state.page2.countryOfBirth}
                        onChange={pageData.handleChangeSelect('page2', 'countryOfBirth')}
                        options={state.countryListOptions}
                        />
                    {state.errors.includes('error_page2_country_of_birth') &&
                        <p className="error_class">{error_msg_detail}</p>
                    }
                </Form.Group>
                <Row className="question_btn">
                    <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={() => pageData.prevPage('page1', 'page2')} >{back_btn}</Button></Col>
                    <Col sm></Col>
                    <Col sm></Col>
                    <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={() => pageData.nextPage('page2', 'page3')} >{next_btn}</Button></Col>
                </Row>
            </Form>
            </>
        )
    }