import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const page13 = (pageData) => {
        let state = pageData.state
        const { t, i18n, classes } = pageData.props;
        let currentDate = new Date()

        let back_btn = t('common_btn.back')
        let next_btn = t('common_btn.next')
        let error_msg_detail = t('error_msg.error_msg_detail')
        let label_page_title = t('question.page_13.page_title')
        let label_date_of_issue = t('question.page_13.date_of_issue')
        let label_date_of_expiry = t('question.page_13.date_of_expiry')

        if(state.page13.issueDate)state.page13.issueDate = new Date(state.page13.issueDate)
        if(state.page13.expiryDate)state.page13.expiryDate = new Date(state.page13.expiryDate)

        return(
            <>
            <p className="question-title">{label_page_title}</p>
            <Form>
            <Form.Row>
                <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>{label_date_of_issue}</Form.Label>
                    <DatePicker
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        maxDate={currentDate}
                        selected={state.page13.issueDate}
                        onSelect={pageData.handleChangeCalendar('page13', 'issueDate')} //when day is clicked
                        onChange={pageData.handleChangeCalendar('page13', 'issueDate')} //only when value has changed
                    />
                    {state.errors.includes('error_page13_issueDate') &&
                        <p className="error_class">{error_msg_detail}</p>
                    }
                </Form.Group>

                <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Label>{label_date_of_expiry}</Form.Label>
                    <DatePicker
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        selected={state.page13.expiryDate}
                        onSelect={pageData.handleChangeCalendar('page13', 'expiryDate')} //when day is clicked
                        onChange={pageData.handleChangeCalendar('page13', 'expiryDate')} //only when value has changed
                    />
                    {state.errors.includes('error_page13_expiryDate') &&
                        <p className="error_class">{error_msg_detail}</p>
                    }
                </Form.Group>
            </Form.Row>
            <Row className="question_btn">
                <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={()=>pageData.prevPage('page11','page13')} >{back_btn}</Button></Col>
                <Col sm></Col>
                <Col sm></Col>
                <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={()=>pageData.nextPage('page13','page14')} >{next_btn}</Button></Col>
            </Row>
        </Form>
    </>
        )
    }