import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';

export const page7 = (pageData) => {
        let state = pageData.state
        const { t, i18n, classes } = pageData.props;

        let back_btn = t('common_btn.back')
        let next_btn = t('common_btn.next')
        let error_msg_detail = t('error_msg.error_msg_detail')
        let label_page_title = t('question.page_7.page_title')
        let label_road = t('question.page_7.road')
        let label_house_number = t('question.page_7.house_number')
        let label_other_address_details = t('question.page_7.other_address_details')

        console.log("PAGE 7 ==> " + state.page7.addressDetails)
        console.log("PAGE 7 ==> " + state.page7.houseNumber)


        return(
            <>
                <p className="question-title">{label_page_title}</p>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>{label_road}</Form.Label>
                            <Form.Control 
                                type="text" 
                                onChange={pageData.handleChange('page7','road')} 
                                value={state.page7.road} 
                            />
                            {state.errors.includes('error_page7_road') &&
                                <p className="error_class">{error_msg_detail}</p>
                            }
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>{label_house_number}</Form.Label>
                            <Form.Control 
                                type="text" 
                                onChange={pageData.handleChange('page7','houseNumber')} 
                                value={state.page7.houseNumber} 
                            />
                            {state.errors.includes('error_page7_houseNumber') &&
                                <p className="error_class">{error_msg_detail}</p>
                            }
                        </Form.Group>
                    </Form.Row>

                    <Form.Group controlId="formGridAddress1">
                        <Form.Label>{label_other_address_details}</Form.Label>
                        <Form.Control 
                            type="text" 
                            onChange={pageData.handleChange('page7','addressDetails')} 
                            value={state.page7.addressDetails} 
                        />
                        {state.errors.includes('error_page7_addressDetails') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Row className="question_btn">
                        <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={()=>pageData.prevPage('page6','page7')} >{back_btn}</Button></Col>
                        <Col sm></Col>
                        <Col sm></Col>
                        <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={()=>pageData.nextPage('page7','page8')} >{next_btn}</Button></Col>
                    </Row>
                </Form>
            </>
        )
    }