import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const page21 = (pageData) => {
        let state = pageData.state
        const { t, i18n, classes } = pageData.props;
        let currentDate = new Date()

        let back_btn = t('common_btn.back')
        let next_btn = t('common_btn.next')
        let error_msg_detail = t('error_msg.error_msg_detail')
        let label_page_title = t('question.page_21.page_title')
        let label_valid_from = t('question.page_21.valid_from')
        let label_date_of_expiry = t('question.page_21.date_of_expiry')

        if(state.page21.validFrom)state.page21.validFrom = new Date(state.page21.validFrom)
        if(state.page21.dateExpiry)state.page21.dateExpiry = new Date(state.page21.dateExpiry)


        return(
            <>
                <p className="question-title">{label_page_title}</p>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>{label_valid_from}</Form.Label>
                            <DatePicker
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                maxDate={currentDate}
                                selected={state.page21.validFrom}
                                onSelect={pageData.handleChangeCalendar('page21', 'validFrom')} //when day is clicked
                                onChange={pageData.handleChangeCalendar('page21', 'validFrom')} //only when value has changed
                            />
                            {state.errors.includes('error_page21_validFrom') &&
                                <p className="error_class">{error_msg_detail}</p>
                            }
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>{label_date_of_expiry}</Form.Label>
                            <DatePicker
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                maxDate={currentDate}
                                selected={state.page21.dateExpiry}
                                onSelect={pageData.handleChangeCalendar('page21', 'dateExpiry')} //when day is clicked
                                onChange={pageData.handleChangeCalendar('page21', 'dateExpiry')} //only when value has changed
                            />
                            {state.errors.includes('error_page21_dateExpiry') &&
                                <p className="error_class">{error_msg_detail}</p>
                            }
                        </Form.Group>
                    </Form.Row>
                    <Row className="question_btn">
                        <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={()=>pageData.prevPage('page20','page21')} >{back_btn}</Button></Col>
                        <Col sm></Col>
                        <Col sm></Col>
                        <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={()=>pageData.nextPage('page21','page22')} >{next_btn}</Button></Col>
                    </Row>
                </Form>
            </>
        )
    }