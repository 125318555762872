import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = {
                            question:{_id: null,
                                question: null,
                                first:false,
                                answers:[
                                    // {"_id":"5f69aa658ad60b00172ad044","text":"Answer of the question"}
                                ]
                            },
                            id_of_answer: null,
                            error_code: null,
                            loading: false,
                            logout: true
                    }


const questionStart = ( state, action ) => {
    return updateObject( state, { error: null, loading: true } );
};

const questionSuccess = (state, action) => {

    return updateObject( state, {
        error_code: null,
        loading: false,
        logout: false,
        form: action.form,
        v2:false,
        id_of_answer: action.id_of_answer

     } );
};

const tokenSuccess = (state, action) => {

    console.log("REDUCER FORM ==> " + action.form)

    return updateObject( state, {
        error_code: null,
        loading: false,
        logout: false,
        form: action.form,
        v2: true,
        id_of_answer: action.id_of_answer

     } );
};

const verifySuccess = (state, action) => {
    
    return updateObject( state, {
        error_code: null,
        loading: false,
        logout: false,
        allowed_state: action.allowed_state
     } );
};

const questionFail = (state, action) => {
    return updateObject( state, {
        error_code: action.error_code,
        loading: false
    });
};


const authLogout = (state, action) => {
    return updateObject(state, { 
        logout:true
        });
};


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.QUESTION_START: return questionStart(state, action);
        case actionTypes.QUESTION_SUCCESS: return questionSuccess(state, action);
        case actionTypes.TOKEN_SUCCESS: return tokenSuccess(state, action);
        case actionTypes.QUESTION_FAIL: return questionFail(state, action);
        case actionTypes.VERIFY_SUCCESS: return verifySuccess(state, action);
        case actionTypes.AUTH_SIGNOUT: return authLogout(state, action);

        default:
    
    return state;
    }
};

export default reducer;