import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from "react-datepicker";

export const referencePage1 = (pageData) => {
    let state = pageData.state
    const { t, i18n, classes } = pageData.props;
    let currentDate = new Date()

    let back_btn = t('common_btn.back')
    let next_btn = t('common_btn.next')
    let label_page_title = t('question.refrence.page_1.page_title')
    let label_family_name = t('question.refrence.page_1.family_name')
    let label_first_name = t('question.refrence.page_1.first_name')
    let label_gender = t('question.refrence.page_1.gender')
    let label_date_of_birth = t('question.refrence.page_1.date_of_birth')
    let label_place_of_birth = t('question.refrence.page_1.place_of_birth')
    let label_nationality = t('question.refrence.page_1.nationality')
    let label_street = t('question.refrence.page_1.road')
    let label_house_number = t('question.refrence.page_1.house_number')
    let label_post_code = t('question.refrence.page_1.post_code')    
    let label_city = t('question.Obligor.page_2.place')
    let label_land = t('question.refrence.page_1.land')
    let label_phone = t('question.refrence.page_1.phone')
    let label_email = t('question.refrence.page_1.email')
    let label_different_surname = t('question.refrence.page_1.different_surname')
    let label_different_firstname = t('question.refrence.page_1.different_firstname')
    let label_other_names = t('question.refrence.page_1.other_names')
    let label_previous_names = t('question.refrence.page_1.previous_names')
    let error_msg_detail = t('error_msg.error_msg_detail')

    let genderOptions = t('gender', {returnObjects:true})


    if(state.referencePage1.dateOfBirth)state.referencePage1.dateOfBirth = new Date(state.referencePage1.dateOfBirth)
    if(state.referencePage1.gender && !state.referencePage1.gender.label)state.referencePage1.gender = JSON.parse(state.referencePage1.gender)
    if(state.referencePage1.nationality && !state.referencePage1.nationality.label)state.referencePage1.nationality = JSON.parse(state.referencePage1.nationality)
    if(state.referencePage1.land && !state.referencePage1.land.label)state.referencePage1.land = JSON.parse(state.referencePage1.land)

    
    return (
        <>
            <p className="question-title">{label_page_title}</p>
            <Form>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_family_name}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage1', 'familyName')}
                            value={state.referencePage1.familyName}
                        />
                        {state.errors.includes('error_referencePage1_familyName') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_first_name}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage1', 'firstName')}
                            value={state.referencePage1.firstName}
                        />
                        {state.errors.includes('error_referencePage1_firstName') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_gender}</Form.Label>
                        <Select
                            onChange={pageData.handleChangeSelect('referencePage1', 'gender')}
                            value={state.referencePage1.gender}
                            options={genderOptions}
                        />
                        {state.errors.includes('error_referencePage1_gender') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    
                    <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Label>{label_date_of_birth}</Form.Label>
                            <DatePicker
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                maxDate={currentDate}
                                selected={state.referencePage1.dateOfBirth}
                                onSelect={pageData.handleChangeCalendar('referencePage1', 'dateOfBirth')} 
                                onChange={pageData.handleChangeCalendar('referencePage1', 'dateOfBirth')}
                            />
                            {state.errors.includes('error_page24_dateOfBirth') &&
                                <p className="error_class">{error_msg_detail}</p>
                            }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_place_of_birth}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage1', 'placeOfBirth')}
                            value={state.referencePage1.placeOfBirth}
                        />
                        {state.errors.includes('error_referencePage1_placeOfBirth') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_nationality}</Form.Label>
                        <Select
                            onChange={pageData.handleChangeSelect('referencePage1', 'nationality')}
                            value={state.referencePage1.nationality}
                            options={state.countryListOptions}
                        />
                        {state.errors.includes('error_referencePage1_nationality') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_street}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage1', 'street')}
                            value={state.referencePage1.street}
                        />
                        {state.errors.includes('error_referencePage1_street') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_house_number}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage1', 'houseNumber')}
                            value={state.referencePage1.houseNumber}
                        />
                        {state.errors.includes('error_referencePage1_houseNumber') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_post_code}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage1', 'postalCode')}
                            value={state.referencePage1.postalCode}
                        />
                        {state.errors.includes('error_referencePage1_postalCode') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_city}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage1', 'placeCountry')}
                            value={state.referencePage1.placeCountry}
                        />
                        {state.errors.includes('error_referencePage1_placeCountry') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_land}</Form.Label>
                        <Select
                            onChange={pageData.handleChangeSelect('referencePage1', 'land')}
                            value={state.referencePage1.land}
                            options={state.countryListOptions}
                        />
                        {state.errors.includes('error_referencePage1_land') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_phone}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage1', 'phone')}
                            value={state.referencePage1.phone}
                        />
                        {state.errors.includes('error_referencePage1_phone') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_email}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage1', 'email')}
                            value={state.referencePage1.email}
                        />
                        {state.errors.includes('error_referencePage1_email') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_different_surname}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage1', 'diffSurName')}
                            value={state.referencePage1.diffSurName}
                        />
                        {state.errors.includes('error_referencePage1_diffSurName') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_different_firstname}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage1', 'diffFirstName')}
                            value={state.referencePage1.diffFirstName}
                        />
                        {state.errors.includes('error_referencePage1_diffFirstName') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_other_names}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage1', 'otherName')}
                            value={state.referencePage1.otherName}
                        />
                        {state.errors.includes('error_referencePage1_other_names') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_previous_names}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage1', 'formerName')}
                            value={state.referencePage1.formerName}
                        />
                        {state.errors.includes('error_referencePage1_previous_names') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    
                </Form.Row>
                <Row className="question_btn">
                    <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={() => pageData.prevPage('page25', 'referencePage1')} >{back_btn}</Button></Col>
                    <Col sm></Col>
                    <Col sm></Col>
                    <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={() => pageData.nextPage('referencePage1', 'page26')} >{next_btn}</Button></Col>
                </Row>
            </Form>
        </>
    )
}