import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const page24 = (pageData) => {
        let state = pageData.state
        const { t, i18n, classes } = pageData.props;
        let currentDate = new Date()

        let back_btn = t('common_btn.back')
        let next_btn = t('common_btn.next')
        let error_msg_detail = t('error_msg.error_msg_detail')
        let label_page_title = t('question.page_24.page_title')
        let label_issued_by = t('question.page_24.issued_by')
        let label_valid_from = t('question.page_24.valid_from')
        let label_date_of_expiry = t('question.page_24.date_of_expiry')

        if(state.page24.validFrom)state.page24.validFrom = new Date(state.page24.validFrom)
        if(state.page24.expiryDate)state.page24.expiryDate = new Date(state.page24.expiryDate)

        return(
            <>
                <p className="question-title">{label_page_title}</p>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>{label_issued_by}</Form.Label>
                            <Form.Control 
                                type="text" 
                                onChange={pageData.handleChange('page24','issuedBy')} 
                                value={state.page24.issuedBy} 
                            />
                            {state.errors.includes('error_page24_issuedBy') &&
                                <p className="error_class">{error_msg_detail}</p>
                            }
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>{label_valid_from}</Form.Label>
                            <DatePicker
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                maxDate={currentDate}
                                selected={state.page24.validFrom}
                                onSelect={pageData.handleChangeCalendar('page24', 'validFrom')} //when day is clicked
                                onChange={pageData.handleChangeCalendar('page24', 'validFrom')} //only when value has changed
                            />
                            {state.errors.includes('error_page24_validFrom') &&
                                <p className="error_class">{error_msg_detail}</p>
                            }
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>{label_date_of_expiry}</Form.Label>
                            <DatePicker
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                selected={state.page24.expiryDate}
                                onSelect={pageData.handleChangeCalendar('page24', 'expiryDate')} //when day is clicked
                                onChange={pageData.handleChangeCalendar('page24', 'expiryDate')} //only when value has changed
                            />
                            {state.errors.includes('error_page24_expiryDate') &&
                                <p className="error_class">{error_msg_detail}</p>
                            }
                        </Form.Group>
                    </Form.Row>
                    <Row className="question_btn">
                        <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={()=>pageData.prevPage('page23','page24')} >{back_btn}</Button></Col>
                        <Col sm></Col>
                        <Col sm></Col>
                        <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={()=>pageData.nextPage('page24','page25')} >{next_btn}</Button></Col>
                    </Row>
                </Form>
            </>
        )
    }