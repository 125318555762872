import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const page16 = (pageData) => {
        let state = pageData.state
        const { t, i18n, classes } = pageData.props;
        let currentDate = new Date()
        
        let back_btn = t('common_btn.back')
        let next_btn = t('common_btn.next')
        let error_msg_detail = t('error_msg.error_msg_detail')
        let label_page_title = t('question.page_16.page_title')
        let label_date_if_known = t('question.page_16.date_if_known')

        if(state.page16.date)state.page16.date = new Date(state.page16.date)


        return(
            <>
                <p className="question-title">{label_page_title}</p>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>{label_date_if_known}</Form.Label>
                            <DatePicker
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                maxDate={currentDate}
                                selected={state.page16.date}
                                onSelect={pageData.handleChangeCalendar('page16', 'date')} //when day is clicked
                                onChange={pageData.handleChangeCalendar('page16', 'date')} //only when value has changed
                            />
                            {state.errors.includes('error_page16_date') &&
                                <p className="error_class">{error_msg_detail}</p>
                            }
                        </Form.Group>
                    </Form.Row>
                    <Row className="question_btn">
                        <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={()=>pageData.prevPage('page15','page16')} >{back_btn}</Button></Col>
                        <Col sm></Col>
                        <Col sm></Col>
                        <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={()=>pageData.nextPage('page16','page17')} >{next_btn}</Button></Col>
                    </Row>
                </Form>
            </>
        )
    }