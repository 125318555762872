import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';


export const page22 = (pageData) => {
        let state = pageData.state
        const { t, i18n, classes } = pageData.props;

        let back_btn = t('common_btn.back')
        let next_btn = t('common_btn.next')
        let error_msg_detail = t('error_msg.error_msg_detail')
        let label_page_title = t('question.page_22.page_title')
        let label_type_of_entry_permit = t('question.page_22.type_of_entry_permit')
        let label_entry_permit_number = t('question.page_22.entry_permit_number')
        let label_type_of_entry_permit_selection_list = t('question.page_22.type_of_entry_permit_selection_list')

        let typeOfEntryPermitOptions = t('typeOfEntryPermit', {returnObjects:true})


        if(state.page22.permitType && !state.page22.permitType.label)state.page22.permitType = JSON.parse(state.page22.permitType)

        return(
            <>
                <p className="question-title">{label_page_title}</p>
                <Form>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>{label_type_of_entry_permit}</Form.Label>
                    <Select
                        value={state.page22.permitType}
                        onChange={pageData.handleChangeSelect('page22','permitType')}
                        options={typeOfEntryPermitOptions}
                    />
                    {state.errors.includes('error_page22_permitType') &&
                        <p className="error_class">{error_msg_detail}</p>
                    }
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Label>{label_entry_permit_number}</Form.Label>
                    <Form.Control 
                        type="text" 
                        onChange={pageData.handleChange('page22','permitNumber')} 
                        value={state.page22.permitNumber} 
                    />
                    {state.errors.includes('error_page22_permitNumber') &&
                        <p className="error_class">{error_msg_detail}</p>
                    }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>{label_type_of_entry_permit_selection_list}</Form.Label>
                    <Form.Control 
                        type="text" 
                        onChange={pageData.handleChange('page22','selectionList')} 
                        value={state.page22.selectionList} 
                    />
                    {state.errors.includes('error_page22_selectionList') &&
                        <p className="error_class">{error_msg_detail}</p>
                    }
                    </Form.Group>
                </Form.Row>
                <Row className="question_btn">
                    <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={()=>pageData.prevPage('page21','page22')} >{back_btn}</Button></Col>
                    <Col sm></Col>
                    <Col sm></Col>
                    <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={()=>pageData.nextPage('page22','page23')} >{next_btn}</Button></Col>
                </Row>
            </Form>
        </>
        )
    }