import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const page11 = (pageData) => {
        let state = pageData.state 
        const { t, i18n, classes } = pageData.props;
        let currentDate = new Date()
        
        let back_btn = t('common_btn.back')
        let next_btn = t('common_btn.next')
        let error_msg_detail = t('error_msg.error_msg_detail')
        let label_page_title = t('question.page_11.page_title')
        let label_residence_permit = t('question.page_11.residence_permit')
        let label_authorixation = t('question.page_11.authorixation')
        let label_valid_this = t('question.page_11.valid_this')


        console.log("PAGE 11 ==> " + state.page11.validDate)

        if(state.page11.validDate)state.page11.validDate = new Date(state.page11.validDate)

        return(
            <>
                <p className="question-title">{label_page_title}</p>
                <Form>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>{label_residence_permit}</Form.Label>
                    <Form.Control 
                        type="text" 
                        onChange={pageData.handleChange('page11','entitlementResidencePermit')} 
                        value={state.page11.entitlementResidencePermit}
                    />
                    {state.errors.includes('error_page11_entitlementResidencePermit') &&
                        <p className="error_class">{error_msg_detail}</p>
                    }
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Label>{label_authorixation}</Form.Label>
                    <Form.Control 
                        type="text" 
                        onChange={pageData.handleChange('page11','authorixationResidencePermit')} 
                        value={state.page11.authorixationResidencePermit} 
                    />
                    {state.errors.includes('error_page11_authorixationResidencePermit') &&
                        <p className="error_class">{error_msg_detail}</p>
                    }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>{label_valid_this}</Form.Label>
                    <DatePicker
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        maxDate={currentDate}
                        selected={state.page11.validDate}
                        onSelect={pageData.handleChangeCalendar('page11', 'validDate')} //when day is clicked
                        onChange={pageData.handleChangeCalendar('page11', 'validDate')} //only when value has changed
                    />
                    {state.errors.includes('error_page11_validDate') &&
                        <p className="error_class">{error_msg_detail}</p>
                    }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridEmail">
                    </Form.Group>
                </Form.Row>
                <Row className="question_btn">
                    <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={()=>pageData.prevPage('page10','page11')} >{back_btn}</Button></Col>
                    <Col sm></Col>
                    <Col sm></Col>
                    <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={()=>pageData.nextPage('page11','page12')} >{next_btn}</Button></Col>
                </Row>
            </Form>
        </>
        )
    }