import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from "react-datepicker";

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

export const referencePage2 = (pageData) => {
    let state = pageData.state
    const { t, i18n, classes } = pageData.props;
    let currentDate = new Date()

    let back_btn = t('common_btn.back')
    let next_btn = t('common_btn.next')
    let label_page_title = t('question.refrence.page_2.page_title')
    let label_name_organization = t('question.refrence.page_2.name_organization')
    let label_headquater_organization = t('question.refrence.page_2.headquater_organization')
    let label_organization_headquaters = t('question.refrence.page_2.organization_headquaters')
    let label_task_organization = t('question.refrence.page_2.task_organization')
    let label_name_organiation = t('question.refrence.page_2.name_organiation')
    let label_place_register = t('question.refrence.page_2.place_register')
    let label_register_number = t('question.refrence.page_2.register_number')
    let label_surname_contact_person = t('question.refrence.page_2.surname_contact_person')
    let label_first_name_contact_person = t('question.refrence.page_2.first_name_contact_person')
    let label_date_of_birth = t('question.refrence.page_2.date_of_birth')
    let label_gender = t('question.refrence.page_2.gender')
    let label_nationality = t('question.refrence.page_2.nationality')
    let label_road = t('question.refrence.page_2.road')
    let label_house_number = t('question.refrence.page_2.house_number')
    let label_post_code = t('question.refrence.page_2.post_code')
    let label_location = t('question.refrence.page_2.location')
    let label_country = t('question.refrence.page_2.country')
    let label_phone = t('question.refrence.page_2.phone')
    let label_email = t('question.refrence.page_2.email')
    let error_msg_detail = t('error_msg.error_msg_detail')

    let genderOptions = t('gender', {returnObjects:true})
    let organization_objectives_list = t('organization_objectives', {returnObjects:true})
    let name_of_register_list = t('name_of_register_of_organization', {returnObjects:true})


    if(state.referencePage2.dateOfBirth)state.referencePage2.dateOfBirth = new Date(state.referencePage2.dateOfBirth)

    return (
        <>
            <p className="question-title">{label_page_title}</p>
            <Form>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_name_organization}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage2', 'organizationName')}
                            value={state.referencePage2.organizationName}
                        />
                        {state.errors.includes('error_referencePage2_organizationName') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_headquater_organization}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage2', 'organizationPlace')}
                            value={state.referencePage2.organizationPlace}
                        />
                        {state.errors.includes('error_referencePage2_organizationPlace') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_organization_headquaters}</Form.Label>
                        <Select
                            onChange={pageData.handleChangeSelect('referencePage2', 'organizationCountry')}
                            value={state.referencePage2.organizationCountry}
                            options={state.countryListOptions}
                        />
                        {state.errors.includes('error_referencePage2_organizationCountry') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_task_organization}</Form.Label>
                        <Select
                            onChange={pageData.handleChangeSelect('referencePage2', 'organizationTask')}
                            value={state.referencePage2.organizationTask}
                            options={organization_objectives_list}
                        />
                        {state.errors.includes('error_referencePage2_organizationTask') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_name_organiation}</Form.Label>
                        <Select
                            onChange={pageData.handleChangeSelect('referencePage2', 'organiationRegisterName')}
                            value={state.referencePage2.organiationRegisterName}
                            options={name_of_register_list}
                        />
                        {state.errors.includes('error_referencePage2_organiationRegisterName') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_place_register}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage2', 'registerPlace')}
                            value={state.referencePage2.registerPlace}
                        />
                        {state.errors.includes('error_referencePage2_registerPlace') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_register_number}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage2', 'registerNumber')}
                            value={state.referencePage2.registerNumber}
                        />
                        {state.errors.includes('error_referencePage2_registerNumber') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_surname_contact_person}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage2', 'contactPersonSurname')}
                            value={state.referencePage2.contactPersonSurname}
                        />
                        {state.errors.includes('error_referencePage2_contactPersonSurname') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_first_name_contact_person}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage2', 'contactPersonFirstname')}
                            value={state.referencePage2.contactPersonFirstname}
                        />
                        {state.errors.includes('error_referencePage2_contactPersonFirstname') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Label>{label_date_of_birth}</Form.Label>
                            <DatePicker
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                maxDate={currentDate}
                                selected={state.referencePage2.dateOfBirth}
                                onSelect={pageData.handleChangeCalendar('referencePage2', 'dateOfBirth')} 
                                onChange={pageData.handleChangeCalendar('referencePage2', 'dateOfBirth')}
                            />
                            {state.errors.includes('error_page24_dateOfBirth') &&
                                <p className="error_class">{error_msg_detail}</p>
                            }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_gender}</Form.Label>
                        <Select
                            onChange={pageData.handleChangeSelect('referencePage2', 'gender')}
                            value={state.referencePage2.gender}
                            options={genderOptions}
                        />
                        {state.errors.includes('error_referencePage2_gender') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_nationality}</Form.Label>
                        <Select
                            onChange={pageData.handleChangeSelect('referencePage2', 'nationality')}
                            value={state.referencePage2.nationality}
                            options={state.countryListOptions}
                        />
                        {state.errors.includes('error_referencePage2_nationality') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_road}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage2', 'road')}
                            value={state.referencePage2.road}
                        />
                        {state.errors.includes('error_referencePage2_road') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_house_number}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage2', 'houseNumber')}
                            value={state.referencePage2.houseNumber}
                        />
                        {state.errors.includes('error_referencePage2_houseNumber') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_post_code}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage2', 'postCode')}
                            value={state.referencePage2.postCode}
                        />
                        {state.errors.includes('error_referencePage2_postCode') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_location}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage2', 'location')}
                            value={state.referencePage2.location}
                        />
                        {state.errors.includes('error_referencePage2_location') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
  
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_country}</Form.Label>
                        <Select
                            onChange={pageData.handleChangeSelect('referencePage2', 'country')}
                            value={state.referencePage2.country}
                            options={state.countryListOptions}
                        />
                        {state.errors.includes('error_referencePage2_organizationCountry') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_phone}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage2', 'phone')}
                            value={state.referencePage2.phone}
                        />
                        {state.errors.includes('error_referencePage2_phone') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_email}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage2', 'email')}
                            value={state.referencePage2.email}
                        />
                        {state.errors.includes('error_referencePage2_email') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Row className="question_btn">
                    <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={() => pageData.prevPage('page25', 'referencePage2')} >{back_btn}</Button></Col>
                    <Col sm></Col>
                    <Col sm></Col>
                    <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={() => pageData.nextPage('referencePage2', 'page26')} >{next_btn}</Button></Col>
                </Row>
            </Form>
        </>
    )
}