import {API_MAIN_URL, LOCAL_STORAGE_TOKEN} from '../shared/consts'

export const authService = {
    signIn,
    signUp,
    signOut,
    validateAuthenticationAndGetData,
    validateTokenAndGetData,
    sendResetPasswordMail,
    confirmResetPassword
};


function signUp( name, email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name, email, password })
    };

    return fetch(`${API_MAIN_URL}/api/user/create`, requestOptions)
        .then(handleResponse)
        .then(data => {

             //store user details and jwt token in local storage to keep user logged in between page refreshes
             data.token = data.token.replace(/['"]+/g, '')
             localStorage.setItem(LOCAL_STORAGE_TOKEN, JSON.stringify(data.token));
            return data;
        });
}

function signIn(email, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
    };

    return fetch(`${API_MAIN_URL}/api/user/login`, requestOptions)
        .then(handleResponse)
        .then(data => {
            // store data details and jwt token in local storage to keep data logged in between page refreshes

            data.token = data.token.replace(/['"]+/g, '')
            localStorage.setItem(LOCAL_STORAGE_TOKEN, JSON.stringify(data.token));

            return data;
        });
}

function signOut() {


    let token = localStorage.getItem(LOCAL_STORAGE_TOKEN)
    token = token.replace(/['"]+/g, '')
    const bearerToken = `Bearer ${token}` 


    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json',
                    'Authorization': bearerToken}
        };

    return fetch(`${API_MAIN_URL}/api/user/logout`, requestOptions)
        .then(handleResponse)
        .then(user => {
           // remove user from local storage to log user out
            localStorage.removeItem(LOCAL_STORAGE_TOKEN);

            return user;
        });
}

function validateAuthenticationAndGetData() {

    let path = window.location.pathname
    const currentLang = path.substring(0,3);
    let langSign = currentLang.split("/")[1]
    const upperCaseLangSign = langSign.toUpperCase()

    let token = localStorage.getItem(LOCAL_STORAGE_TOKEN)


    if(token)token = token.replace(/['"]+/g, '')

    const bearerToken = `Bearer ${token}` 


    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
            'Authorization': bearerToken}
        };

    return fetch(`${API_MAIN_URL}/api/user/v2/verify`, requestOptions)
        .then(handleResponse)
        .then(data => {

           
            return data;
        });
}

function validateTokenAndGetData(id) {

    let path = window.location.pathname
    const currentLang = path.substring(0,3);
    let langSign = currentLang.split("/")[1]
    const upperCaseLangSign = langSign.toUpperCase()


    if(id){
        id = id.replace(/['"]+/g, '')
        localStorage.setItem(LOCAL_STORAGE_TOKEN, JSON.stringify(id))
    }

    let token = localStorage.getItem(LOCAL_STORAGE_TOKEN)


    if(token)token = token.replace(/['"]+/g, '')

    const bearerToken = `Bearer ${token}` 


    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
            'Authorization': bearerToken}
        };

    return fetch(`${API_MAIN_URL}/api/user/v2/verify`, requestOptions)
        .then(handleResponse)
        .then(data => {


            console.log("API SERVICE FORM  ==> " + data.form)

           
            return data;
        });
}

function sendResetPasswordMail(mail) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        query: {mail}
    };

    return fetch(`${API_MAIN_URL}/api/user/forgotpassword?mail=${mail}`, requestOptions)
        .then(handleResponse)
        .then(data => {

            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem(LOCAL_STORAGE_TOKEN, JSON.stringify(user));

            return data;
        });
}

function confirmResetPassword(token, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({password})
    };

    return fetch(`${API_MAIN_URL}/api/user/resetpassword/${token}`, requestOptions)
        .then(handleResponse)
        .then(data => {

            // store user details and jwt token in local storage to keep user logged in between page refreshes
            // localStorage.setItem(LOCAL_STORAGE_TOKEN, JSON.stringify(user));

            return data;
        });
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //signOut();

                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}