import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Select from 'react-select';

export const obligorPage2 = (pageData) => {
    let state = pageData.state
    const { t, i18n, classes } = pageData.props;

    let back_btn = t('common_btn.back')
    let next_btn = t('common_btn.next')
    let label_page_title = t('question.Obligor.page_2.page_title')
    let label_deviating_obligor = t('question.Obligor.page_2.deviating_obligor')
    let label_headquater_organization = t('question.Obligor.page_2.headquater_organization')
    let label_organization_headquaters = t('question.Obligor.page_2.organization_headquaters')
    let label_task_organization = t('question.Obligor.page_2.task_organization')
    let label_name_organiation = t('question.Obligor.page_2.name_organiation')
    let label_place_register = t('question.Obligor.page_2.place_register')
    let label_register_number = t('question.Obligor.page_2.register_number')
    let label_surname_contact_person = t('question.Obligor.page_2.surname_contact_person')
    let label_first_name_contact_person = t('question.Obligor.page_2.first_name_contact_person')
    let label_gender = t('question.Obligor.page_2.gender')
    let label_road = t('question.Obligor.page_2.road')
    let label_house_number = t('question.Obligor.page_2.house_number')
    let label_post_code = t('question.Obligor.page_2.post_code')
    let label_place = t('question.Obligor.page_2.place')
    let label_country = t('question.Obligor.page_2.country')
    let label_phone = t('question.Obligor.page_2.phone')
    let label_email = t('question.Obligor.page_2.email')
    let error_msg_detail = t('error_msg.error_msg_detail')

    
    let genderOptions = t('gender', {returnObjects:true})
    let organization_objectives_list = t('organization_objectives', {returnObjects:true})
    let name_of_register_list = t('name_of_register_of_organization', {returnObjects:true})


    if(state.obligorPage2.dateOfBirth)state.obligorPage2.dateOfBirth = new Date(state.obligorPage2.dateOfBirth)
    if(state.obligorPage2.gender && !state.obligorPage2.gender.label)state.obligorPage2.gender = JSON.parse(state.obligorPage2.gender)
    if(state.obligorPage2.organizationTask && !state.obligorPage2.organizationTask.label)state.obligorPage2.organizationTask = JSON.parse(state.obligorPage2.organizationTask)
    if(state.obligorPage2.organiationRegisterName && !state.obligorPage2.organiationRegisterName.label)state.obligorPage2.organiationRegisterName = JSON.parse(state.obligorPage2.organiationRegisterName)
    if(state.obligorPage2.country && !state.obligorPage2.country.label)state.obligorPage2.country = JSON.parse(state.obligorPage2.country)



    return (
        <>
            <p className="question-title">{label_page_title}</p>
            <Form>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_deviating_obligor}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage2', 'deviatingObligor')}
                            value={state.obligorPage2.deviatingObligor}
                        />
                        {state.errors.includes('error_obligorPage2_deviatingObligor') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_headquater_organization}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage2', 'organizationPlace')}
                            value={state.obligorPage2.organizationPlace}
                        />
                        {state.errors.includes('error_obligorPage2_organizationPlace') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_organization_headquaters}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage2', 'organizationCountry')}
                            value={state.obligorPage2.organizationCountry}
                        />
                        {state.errors.includes('error_obligorPage2_organizationCountry') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_task_organization}</Form.Label>
                        <Select
                            onChange={pageData.handleChangeSelect('obligorPage2', 'organizationTask')}
                            value={state.obligorPage2.organizationTask}
                            options={organization_objectives_list}
                        />
                        {state.errors.includes('error_obligorPage2_organizationTask') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_name_organiation}</Form.Label>
                        <Select
                            onChange={pageData.handleChangeSelect('obligorPage2', 'organiationRegisterName')}
                            value={state.obligorPage2.organiationRegisterName}
                            options={name_of_register_list}
                        />
                        {state.errors.includes('error_obligorPage2_organiationRegisterName') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_place_register}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage2', 'registerPlace')}
                            value={state.obligorPage2.registerPlace}
                        />
                        {state.errors.includes('error_obligorPage2_registerPlace') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_register_number}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage2', 'registerNumber')}
                            value={state.obligorPage2.registerNumber}
                        />
                        {state.errors.includes('error_obligorPage2_registerNumber') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_surname_contact_person}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage2', 'contactPersonSurname')}
                            value={state.obligorPage2.contactPersonSurname}
                        />
                        {state.errors.includes('error_obligorPage2_contactPersonSurname') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_first_name_contact_person}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage2', 'contactPersonFirstname')}
                            value={state.obligorPage2.contactPersonFirstname}
                        />
                        {state.errors.includes('error_obligorPage2_contactPersonFirstname') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_gender}</Form.Label>
                        <Select
                            onChange={pageData.handleChangeSelect('obligorPage2', 'gender')}
                            value={state.obligorPage2.gender}
                            options={genderOptions}
                        />
                        {state.errors.includes('error_obligorPage2_gender') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_road}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage2', 'road')}
                            value={state.obligorPage2.road}
                        />
                        {state.errors.includes('error_obligorPage2_road') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_house_number}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage2', 'houseNumber')}
                            value={state.obligorPage2.houseNumber}
                        />
                        {state.errors.includes('error_obligorPage2_houseNumber') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_post_code}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage2', 'postCode')}
                            value={state.obligorPage2.postCode}
                        />
                        {state.errors.includes('error_obligorPage2_postCode') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_place} </Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage2', 'place')}
                            value={state.obligorPage2.place}
                        />
                        {state.errors.includes('error_obligorPage2_place') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    {/* <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_country}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage2', 'country')}
                            value={state.obligorPage2.country}
                        />
                        {state.errors.includes('error_obligorPage2_country') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group> */}
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_country}</Form.Label>
                        <Select
                            onChange={pageData.handleChangeSelect('obligorPage2', 'country')}
                            value={state.obligorPage2.country}
                            options={state.countryListOptions}
                        />
                        {state.errors.includes('error_obligorPage2_organizationCountry') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_phone}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage2', 'phone')}
                            value={state.obligorPage2.phone}
                        />
                        {state.errors.includes('error_obligorPage2_phone') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_email} </Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage2', 'email')}
                            value={state.obligorPage2.email}
                        />
                        {state.errors.includes('error_obligorPage2_email') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Row className="question_btn">
                    <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={() => pageData.prevPage('page28', 'obligorPage2')} >{back_btn}</Button></Col>
                    <Col sm></Col>
                    <Col sm></Col>
                    <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={()=>pageData.nextPage('obligorPage2','page29')} >{next_btn}</Button></Col>
                </Row>
            </Form>
        </>
    )
}