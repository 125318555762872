import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';

import order_suceed_image from '../../assets/img/order_suceed.gif'

export const Page32 = (pageData) => {
        let state = pageData.state
        const { t, i18n, classes } = pageData.props;

        let back_btn = t('common_btn.back')
        let go_to_homepage_btn = t('common_btn.go_to_homepage')


        let label_page_title = t('question.page_32.page_title')
        return(
            <>


                <img style={{width:"300px"}} src={order_suceed_image}></img>
                <p className="question-title">{label_page_title}</p> 

            
                <Row className="question_btn">
                    <div style={{width:"100%", position:"relative", height:"50px"}}>
                    <Button style={{maxWidth:"300px", color:"white", position:"absolute", left: "50%",webkitTransform: "translateX(-50%)",transform: "translateX(-50%)"}} variant="outline-primary" size="lg" block className="next_btn" >{go_to_homepage_btn}</Button>
                    </div>
                </Row>
            </>
        )
    }