import React from 'react';
import { Link } from 'react-router-dom';

const CheckEmail = (props) => {

    return (
        <>
            <div className="auth-wrapper align-self-center mt-5">
                <div className="auth-inner check-email">
                    <form>
                        <h3 className="mb-4 text-center">{props.lang === "EN" ? "Please check your Mails" : "Bitte überprüfe deine E-Mails"}</h3>
                        <p className="text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum. Lorem ipsum dolor sit amet.</p>
                        <Link to={props.lang === "EN" ? "/en/signin" : "/de/signin"}><button type="submit" className="btn btn-primary btn-block blue-btn mt-4">{props.lang === "EN" ? "Back to Sign in" : "Zurück zum Anmelden"}</button></Link>
                    </form>
                </div>
            </div>
        </>
    );
}

export default CheckEmail;