export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const checkValidity = ( value, rules ) => {
    let isValid = true;
    if ( !rules ) {
        return true;
    }

    if ( rules.required ) {
        isValid = value.trim() !== '' && isValid;
    }

    if ( rules.minLength ) {
        isValid = value.length >= rules.minLength && isValid
    }

    if ( rules.maxLength ) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if ( rules.isEmail ) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test( value ) && isValid
    }

    if ( rules.isNumeric ) {
        const pattern = /^\d+$/;
        isValid = pattern.test( value ) && isValid
    }

    return isValid;
}



export const transformPageToValue = ( page ) => {
    

    let returnValue;

    switch(page){

        case "page4":
        returnValue = 1
        break;
        case "page2":
        returnValue = 2
        break;
        case "page3":
        returnValue = 3
        break;
        case "page4":
        returnValue = 4
        break;
        case "page5":
        returnValue = 5
        break;
        case "page6":
        returnValue = 6
        break;
        case "page7":
        returnValue = 7
        break;
        case "page8":
        returnValue = 8
        break;
        case "page9":
        returnValue = 9
        break;
        case "page10":
        returnValue = 10
        break;
        case "page11":
        returnValue = 11
        break;
        case "page12":
        returnValue = 12
        break;
        case "page13":
        returnValue = 13
        break;
        case "page14":
        returnValue = 14
        break;
        case "page15":
        returnValue = 15
        break;
        case "page16":
        returnValue = 16
        break;
        case "page17":
        returnValue = 17
        break;
        case "page18":
        returnValue = 18
        break;
        case "page19":
        returnValue = 19
        break;
        case "page20":
        returnValue = 20
        break;
        case "page21":
        returnValue = 21
        break;
        case "page22":
        returnValue = 22
        break;
        case "page23":
        returnValue = 23
        break;
        case "page24":
        returnValue = 24
        break;
        case "page25":
        returnValue = 25
        break;
        case "referencePage1":
        returnValue = 26
        break;
        case "referencePage2":
        returnValue = 26
        break;
        case "referencePage3":
        returnValue = 26
        break;
        case "referencePage4":
        returnValue = 26
        break;
        case "page26":
        returnValue = 27
        break;
        case "page27":
        returnValue = 28
        break;
        case "page28":
        returnValue = 29
        break;
        case "obligorPage1":
        returnValue = 30
        break;
        case "obligorPage2":
        returnValue = 30
        break;
        case "obligorPage3":
        returnValue = 30
        break;
        case "page29":
        returnValue = 31
        break;
        case "page30":
        returnValue = 31
        break;
        case "page31":
        returnValue = 31
        break;
        case "page32":
        returnValue = 31
        break;
        
        
    }

    return returnValue;
}





export const transformPropsForm = ( form ) => {
    

    let returnForm = {
        page1 : {
            familyName : "",
            birthName : "",
            firstName : "",
            pageStatus : false
        },
        page2 : {
            dateOfBirth : new Date(),
            placeOfBirth : "",
            countryOfBirth : "",
            pageStatus : false
        },
        page3 : {
            gender : "",
            maritalStatus : "",
            pageStatus : false
        },
        page4 : {
            currentCitizenship : "",
            orignalCitizenship : "",
            pageStatus : false
        },
        page5 : {
            answer : "yes",
            pageStatus : false
        },
        page6 : {
            currentOccupation : "",
            pageStatus : false
        },
        page61 : {
            family_relationship: "",
            family_name: "",
            first_name: "",
            date_of_birth: "",
            current_nationality: "",
            travel_document: "",
            pageStatus : false
        },
        page7 : {
            road : "",
            houseNumber : "",
            addressDetails : "",
            pageStatus : false
        },
        page8 : {
            postal_code : "",
            place : "",
            land : "",
            pageStatus : false
        },
        page9 : {
            telephone : "",
            email : "",
            pageStatus : false
        },
        page10 : {
            answer : "yes",
            pageStatus : false
        },
        page11 : {
            entitlementResidencePermit : "",
            authorixationResidencePermit : "", 
            validDate : new Date(), 
            pageStatus : false
        },
        page12 : {
            travelDocument : "",
            numberOfTravelDocument : "", 
            identityNumber : "", 
            pageStatus : false
        },
        page13 : {
            issueDate : new Date(),
            expiryDate : new Date(), 
            pageStatus : false
        },
        page14 : {
            issuingState : "",
            issuedBy : "", 
            issuedIn : "", 
            pageStatus : false
        },
        page15 : {  
            answer : "yes",
            pageStatus : false
        },
        page16 : {  
            date : new Date(),
            pageStatus : false
        },
        page17 : {  
            mainIssue : "",
            other : "",
            memberState : "",
            pageStatus : false
        },
        page18 : {  
            destination : "",
            numberOfEntries : "",
            pageStatus : false
        },
        page19 : {  
            arrivalDate : new Date(),
            departureDate : new Date(),
            duration : "",
            pageStatus : false
        },
        page20 : {  
            answer : "yes",
            pageStatus : false
        },
        page21 : {  
            validFrom : new Date(),
            dateExpiry : new Date(),
            pageStatus : false
        },
        page22 : {  
            permitType  : "",
            permitNumber : "",
            selectionList : "",
            pageStatus : false
        },
        page23 : {  
            country  : "",
            pageStatus : false
        },
        page24 : {  
            issuedBy  : "",
            validFrom  : new Date(),
            expiryDate  : new Date(),
            pageStatus : false
        },
        page25 : {  
            referencePageSelection  : "",
            pageStatus : false
        },
        hideShowReferencePage : false,
        referencePage1 : {  
            familyName : "",
            firstName : "",
            gender : "",
            dateOfBirth: new Date(),
            placeOfBirth : "",
            nationality : "",
            stralle : "",
            hateNumber : "",
            postalCode : "",
            placeCountry : "",
            land : "",
            phone : "",
            email : "",
            diffSurName : "",
            diffFirstName : "",
            otherName : "",
            formerName : "",
            pageStatus : false
        },
        referencePage2 : {  
            organizationName : "",
            organizationPlace : "",
            organizationCountry : "",
            organizationTask : "",
            organiationRegisterName : "",
            registerPlace : "",
            registerNumber : "",
            contactPersonSurname : "",
            contactPersonFirstname : "",
            dateOfBirth: new Date(),
            gender : "",
            nationality : "",
            road : "",
            houseNumber : "",
            postCode : "",
            location : "",
            country : "",
            phone : "",
            email : "",
            pageStatus : false
        },
        referencePage3 : {  
            organizationName : "",
            organizationPlace : "",
            organizationCountry : "",
            organizationTask : "",
            organiationRegisterName : "",
            registerPlace : "",
            registerNumber : "",
            hotelReservationNumber : "",
            contactPersonSurname : "",
            contactPersonFirstname : "",
            road : "",
            houseNumber : "",
            postCode : "",
            location : "",
            country : "",
            phone : "",
            email : "",
            pageStatus : false
        },
        referencePage4 : {  
            organizationName: "",
            familyName : "",
            firstName : "",
            gender : "",
            dateOfBirth: new Date(),
            placeOfBirth : "",
            nationality : "",
            road : "",
            houseNumber : "",
            postCode : "",
            location : "",
            country : "",
            phone : "",
            email : "",
            diffSurName : "",
            diffFirstName : "",
            otherName : "",
            formerName : "",
            pageStatus : false
        },
        page26 : {  
            applicant : true,
            thirdParty : false,
            invitingParty : false,
            other : false,
            pageStatus : false
        },
        page27 : {
            cash : false,
            traveler : false,
            creditCard : false,
            prepaidAccommodation : false,
            allCosts : false,
            prepaidTransportation : false,
            otherChecks : false,
            pageStatus : false
        },
        page28 : {
            obligorPageSelection : "",
            pageStatus : false
        },
        page29 : {
            pageStatus : false
        },
        obligorPage1 : {
            contactPersonSurname : "",
            contactPersonFirstname : "",
            gender : "",
            dateOfBirth: new Date(),
            placeOfBirth : "",
            nationality : "",
            road : "",
            houseNumber : "",
            postCode : "",
            location : "",
            country : "",
            phone : "",
            email : "",
            diffSurName : "",
            diffFirstName : "",
            otherName : "",
            formerName : "",
            pageStatus : false
        },
        obligorPage2 : {
            deviatingObligor : "",
            organizationPlace : "",
            organizationCountry : "",
            organizationTask : "",
            organiationRegisterName : "",
            registerPlace : "",
            registerNumber : "",
            contactPersonSurname : "",
            contactPersonFirstname : "",
            gender : "",
            road : "",
            houseNumber : "",
            postCode : "",
            place : "",
            country : "",
            phone : "",
            email : "",
            pageStatus : false
        },
        obligorPage3 : {
            insitutionName : "",
            contactPersonSurname : "",
            contactPersonFirstname : "",
            gender : "",
            dateOfBirth: new Date(),
            placeOfBirth : "",
            nationality : "",
            road : "",
            houseNumber : "",
            postCode : "",
            location : "",
            country : "",
            phone : "",
            email : "",
            pageStatus : false
        }
        }

        if(!form)return returnForm

    if(form.page1){
        returnForm.page1.familyName = form.page1.familyName
        returnForm.page1.birthName = form.page1.birthName
        returnForm.page1.firstName = form.page1.firstName
    }
    if(form.page2){
        returnForm.page2.dateOfBirth = form.page2.dateOfBirth
        returnForm.page2.placeOfBirth = form.page2.placeOfBirth
        returnForm.page2.countryOfBirth = form.page2.countryOfBirth
    }
    if(form.page3){
        returnForm.page3.gender = form.page3.gender
        returnForm.page3.maritalStatus = form.page3.maritalStatus
        returnForm.page3.birthStatus = form.page3.birthStatus
    }
    if(form.page4){
        returnForm.page4.currentCitizenship = form.page4.currentCitizenship
        returnForm.page4.orignalCitizenship = form.page4.orignalCitizenship
    }
    if(form.page5){
        returnForm.page5.answer = form.page5.answer
    }
    if(form.page6){
        returnForm.page6.currentOccupation = form.page6.currentOccupation
    }
    if(form.page61){
        returnForm.page61.family_relationship = form.page61.family_relationship
        returnForm.page61.family_name = form.page61.family_name
        returnForm.page61.first_name = form.page61.first_name
        returnForm.page61.date_of_birth = form.page61.date_of_birth
        returnForm.page61.current_nationality = form.page61.current_nationality
        returnForm.page61.travel_document = form.page61.travel_document
    }
    if(form.page7){
        returnForm.page7.road = form.page7.road
        returnForm.page7.houseNumber = form.page7.houseNumber
        returnForm.page7.addressDetails = form.page7.addressDetails
    }
    if(form.page8){
        returnForm.page8.postal_code = form.page8.postal_code
        returnForm.page8.place = form.page8.place
        returnForm.page8.land = form.page8.land
    }
    if(form.page9){
        returnForm.page9.telephone = form.page9.telephone
        returnForm.page9.email = form.page9.email
    }
    if(form.page10){
        returnForm.page10.answer = form.page10.answer
    }
    if(form.page11){
        returnForm.page11.entitlementResidencePermit = form.page11.entitlementResidencePermit
        returnForm.page11.authorixationResidencePermit = form.page11.authorixationResidencePermit
        returnForm.page11.validDate = form.page11.validDate
    }
    if(form.page12){
        returnForm.page12.travelDocument = form.page12.travelDocument
        returnForm.page12.numberOfTravelDocument = form.page12.numberOfTravelDocument
        returnForm.page12.identityNumber = form.page12.identityNumber
    }
    if(form.page13){
        returnForm.page13.issueDate = form.page13.issueDate
        returnForm.page13.expiryDate = form.page13.expiryDate
    }
    if(form.page14){
        returnForm.page14.issuingState = form.page14.issuingState
        returnForm.page14.issuedBy = form.page14.issuedBy
        returnForm.page14.issuedIn = form.page14.issuedIn
    }
    if(form.page15){
        returnForm.page15.answer = form.page15.answer
    }
    if(form.page16){
        returnForm.page16.date = form.page16.date
    }
    if(form.page17){
        returnForm.page17.mainIssue = form.page17.mainIssue
        returnForm.page17.other = form.page17.other
        returnForm.page17.memberState = form.page17.memberState
    }
    if(form.page18){
        returnForm.page18.destination = form.page18.destination
        returnForm.page18.numberOfEntries = form.page18.numberOfEntries
    }
    if(form.page19){
        returnForm.page19.arrivalDate = form.page19.arrivalDate
        returnForm.page19.departureDate = form.page19.departureDate
        returnForm.page19.duration = form.page19.duration
    }
    if(form.page20){
        returnForm.page20.answer = form.page20.answer
    }
    if(form.page21){
        returnForm.page21.validFrom = form.page21.validFrom
        returnForm.page21.dateExpiry = form.page21.dateExpiry
    }
    if(form.page22){
        returnForm.page22.permitType = form.page22.permitType
        returnForm.page22.permitNumber = form.page22.permitNumber
        returnForm.page22.selectionList = form.page22.selectionList
    }
    if(form.page23){
        returnForm.page23.country = form.page23.country
    }
    if(form.page24){
        returnForm.page24.issuedBy = form.page24.issuedBy
        returnForm.page24.validFrom = form.page24.validFrom
        returnForm.page24.expiryDate = form.page24.expiryDate
    }
    if(form.page25){
        returnForm.page25.referencePageSelection = form.page25.referencePageSelection
    }
    if(form.referencePage1){
        returnForm.referencePage1.familyName = form.referencePage1.familyName
        returnForm.referencePage1.referencePageSelection = form.referencePage1.referencePageSelection
        returnForm.referencePage1.firstName = form.referencePage1.firstName
        returnForm.referencePage1.gender = form.referencePage1.gender
        returnForm.referencePage1.dateOfBirth = form.referencePage1.dateOfBirth
        returnForm.referencePage1.placeOfBirth = form.referencePage1.placeOfBirth
        returnForm.referencePage1.nationality = form.referencePage1.nationality
        returnForm.referencePage1.street = form.referencePage1.street
        returnForm.referencePage1.houseNumber = form.referencePage1.houseNumber
        returnForm.referencePage1.postalCode = form.referencePage1.postalCode
        returnForm.referencePage1.placeCountry = form.referencePage1.placeCountry
        returnForm.referencePage1.land = form.referencePage1.land
        returnForm.referencePage1.phone = form.referencePage1.phone
        returnForm.referencePage1.email = form.referencePage1.email
        returnForm.referencePage1.diffSurName = form.referencePage1.diffSurName
        returnForm.referencePage1.diffFirstName = form.referencePage1.diffFirstName
        returnForm.referencePage1.otherName = form.referencePage1.otherName
        returnForm.referencePage1.formerName = form.referencePage1.formerName

    }
    if(form.referencePage2){
        returnForm.referencePage2.organizationName = form.referencePage2.organizationName
        returnForm.referencePage2.organizationPlace = form.referencePage2.organizationPlace
        returnForm.referencePage2.organizationCountry = form.referencePage2.organizationCountry
        returnForm.referencePage2.organizationTask = form.referencePage2.organizationTask
        returnForm.referencePage2.organiationRegisterName = form.referencePage2.organiationRegisterName
        returnForm.referencePage2.registerPlace = form.referencePage2.registerPlace
        returnForm.referencePage2.registerNumber = form.referencePage2.registerNumber
        returnForm.referencePage2.contactPersonSurname = form.referencePage2.contactPersonSurname
        returnForm.referencePage2.contactPersonFirstname = form.referencePage2.contactPersonFirstname
        returnForm.referencePage2.dateOfBirth = form.referencePage2.dateOfBirth
        returnForm.referencePage2.gender = form.referencePage2.gender
        returnForm.referencePage2.nationality = form.referencePage2.nationality
        returnForm.referencePage2.road = form.referencePage2.road
        returnForm.referencePage2.houseNumber = form.referencePage2.houseNumber
        returnForm.referencePage2.postCode = form.referencePage2.postCode
        returnForm.referencePage2.location = form.referencePage2.location
        returnForm.referencePage2.country = form.referencePage2.country
        returnForm.referencePage2.phone = form.referencePage2.phone
        returnForm.referencePage2.email = form.referencePage2.email
    }
    if(form.referencePage3){
        returnForm.referencePage3.organizationName = form.referencePage3.organizationName
        returnForm.referencePage3.organizationPlace = form.referencePage3.organizationPlace
        returnForm.referencePage3.organizationCountry = form.referencePage3.organizationCountry
        returnForm.referencePage3.organizationTask = form.referencePage3.organizationTask
        returnForm.referencePage3.organiationRegisterName = form.referencePage3.organiationRegisterName
        returnForm.referencePage3.registerPlace = form.referencePage3.registerPlace
        returnForm.referencePage3.registerNumber = form.referencePage3.registerNumber
        returnForm.referencePage3.hotelReservationNumber = form.referencePage3.hotelReservationNumber
        returnForm.referencePage3.contactPersonSurname = form.referencePage3.contactPersonSurname
        returnForm.referencePage3.contactPersonFirstname = form.referencePage3.contactPersonFirstname
        returnForm.referencePage3.road = form.referencePage3.road
        returnForm.referencePage3.houseNumber = form.referencePage3.houseNumber
        returnForm.referencePage3.postCode = form.referencePage3.postCode
        returnForm.referencePage3.location = form.referencePage3.location
        returnForm.referencePage3.country = form.referencePage3.country
        returnForm.referencePage3.phone = form.referencePage3.phone
        returnForm.referencePage3.email = form.referencePage3.email
    }
    if(form.referencePage4){
        returnForm.referencePage4.organizationName = form.referencePage4.organizationName
        returnForm.referencePage4.familyName = form.referencePage4.familyName
        returnForm.referencePage4.firstName = form.referencePage4.firstName
        returnForm.referencePage4.gender = form.referencePage4.gender
        returnForm.referencePage4.dateOfBirth = form.referencePage4.dateOfBirth
        returnForm.referencePage4.placeOfBirth = form.referencePage4.placeOfBirth
        returnForm.referencePage4.nationality = form.referencePage4.nationality
        returnForm.referencePage4.road = form.referencePage4.road
        returnForm.referencePage4.houseNumber = form.referencePage4.houseNumber
        returnForm.referencePage4.postCode = form.referencePage4.postCode
        returnForm.referencePage4.location = form.referencePage4.location
        returnForm.referencePage4.country = form.referencePage4.country
        returnForm.referencePage4.phone = form.referencePage4.phone
        returnForm.referencePage4.email = form.referencePage4.email
        returnForm.referencePage4.diffSurName = form.referencePage4.diffSurName
        returnForm.referencePage4.diffFirstName = form.referencePage4.diffFirstName
        returnForm.referencePage4.otherName = form.referencePage4.otherName
        returnForm.referencePage4.formerName = form.referencePage4.formerName
    }

    if(form.page26){
        returnForm.page26.applicant = form.page26.applicant
        returnForm.page26.thirdParty = form.page26.thirdParty
        returnForm.page26.invitingParty = form.page26.invitingParty
        returnForm.page26.other = form.page26.other
    }
    if(form.page27){
        returnForm.page27.cash = form.page27.cash
        returnForm.page27.traveler = form.page27.traveler
        returnForm.page27.creditCard = form.page27.creditCard
        returnForm.page27.prepaidAccommodation = form.page27.prepaidAccommodation
        returnForm.page27.allCosts = form.page27.allCosts
        returnForm.page27.prepaidTransportation = form.page27.prepaidTransportation
        returnForm.page27.otherChecks = form.page27.otherChecks

    }

    if(form.page28){
        returnForm.page28.obligorPageSelection = form.page28.obligorPageSelection
    }

    if(form.obligorPage1){
        returnForm.obligorPage1.contactPersonSurname = form.obligorPage1.contactPersonSurname
        returnForm.obligorPage1.contactPersonFirstname = form.obligorPage1.contactPersonFirstname
        returnForm.obligorPage1.gender = form.obligorPage1.gender
        returnForm.obligorPage1.dateOfBirth = form.obligorPage1.dateOfBirth
        returnForm.obligorPage1.placeOfBirth = form.obligorPage1.placeOfBirth
        returnForm.obligorPage1.nationality = form.obligorPage1.nationality
        returnForm.obligorPage1.road = form.obligorPage1.road
        returnForm.obligorPage1.houseNumber = form.obligorPage1.houseNumber
        returnForm.obligorPage1.postCode = form.obligorPage1.postCode
        returnForm.obligorPage1.location = form.obligorPage1.location
        returnForm.obligorPage1.country = form.obligorPage1.country
        returnForm.obligorPage1.phone = form.obligorPage1.phone
        returnForm.obligorPage1.email = form.obligorPage1.email
        returnForm.obligorPage1.diffSurName = form.obligorPage1.diffSurName
        returnForm.obligorPage1.diffFirstName = form.obligorPage1.diffFirstName
        returnForm.obligorPage1.otherName = form.obligorPage1.otherName
        returnForm.obligorPage1.formerName = form.obligorPage1.formerName

    }

    if(form.obligorPage2){
        returnForm.obligorPage2.deviatingObligor = form.obligorPage2.deviatingObligor
        returnForm.obligorPage2.organizationPlace = form.obligorPage2.organizationPlace
        returnForm.obligorPage2.organizationCountry = form.obligorPage2.organizationCountry
        returnForm.obligorPage2.organizationTask = form.obligorPage2.organizationTask
        returnForm.obligorPage2.organiationRegisterName = form.obligorPage2.organiationRegisterName
        returnForm.obligorPage2.registerPlace = form.obligorPage2.registerPlace
        returnForm.obligorPage2.registerNumber = form.obligorPage2.registerNumber
        returnForm.obligorPage2.contactPersonSurname = form.obligorPage2.contactPersonSurname
        returnForm.obligorPage2.contactPersonFirstname = form.obligorPage2.contactPersonFirstname
        returnForm.obligorPage2.gender = form.obligorPage2.gender
        returnForm.obligorPage2.dateOfBirth = form.obligorPage2.dateOfBirth
        returnForm.obligorPage2.placeOfBirth = form.obligorPage2.placeOfBirth
        returnForm.obligorPage2.nationality = form.obligorPage2.nationality
        returnForm.obligorPage2.road = form.obligorPage2.road
        returnForm.obligorPage2.houseNumber = form.obligorPage2.houseNumber
        returnForm.obligorPage2.postCode = form.obligorPage2.postCode
        returnForm.obligorPage2.place = form.obligorPage2.place
        returnForm.obligorPage2.country = form.obligorPage2.country
        returnForm.obligorPage2.phone = form.obligorPage2.phone
        returnForm.obligorPage2.email = form.obligorPage2.email

    }

    if(form.obligorPage3){

        returnForm.obligorPage3.insitutionName = form.obligorPage3.insitutionName
        returnForm.obligorPage3.contactPersonSurname = form.obligorPage3.contactPersonSurname
        returnForm.obligorPage3.contactPersonFirstname = form.obligorPage3.contactPersonFirstname
        returnForm.obligorPage3.gender = form.obligorPage3.gender
        returnForm.obligorPage3.dateOfBirth = form.obligorPage3.dateOfBirth
        returnForm.obligorPage3.placeOfBirth = form.obligorPage3.placeOfBirth
        returnForm.obligorPage3.nationality = form.obligorPage3.nationality
        returnForm.obligorPage3.road = form.obligorPage3.road
        returnForm.obligorPage3.houseNumber = form.obligorPage3.houseNumber
        returnForm.obligorPage3.postCode = form.obligorPage3.postCode
        returnForm.obligorPage3.location = form.obligorPage3.location
        returnForm.obligorPage3.country = form.obligorPage3.country
        returnForm.obligorPage3.phone = form.obligorPage3.phone
        returnForm.obligorPage3.email = form.obligorPage3.email

    }

    return returnForm;
}
