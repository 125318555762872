import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Select from 'react-select';

export const page23 = (pageData) => {
        let state = pageData.state
        const { t, i18n, classes } = pageData.props;

        let back_btn = t('common_btn.back')
        let next_btn = t('common_btn.next')
        let error_msg_detail = t('error_msg.error_msg_detail')
        let label_page_title = t('question.page_23.page_title')
        let label_final_destination_country = t('question.page_23.final_destination_country')

        if(state.page23.country && !state.page23.country.label)state.page23.country = JSON.parse(state.page23.country)

        return(
            <>
                <p className="question-title">{label_page_title}</p>
                <Form>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>{label_final_destination_country}</Form.Label>
                    <Select
                        value={state.page23.country}
                        onChange={pageData.handleChangeSelect('page23','country')}
                        options={state.countryListOptions}
                    />
                    {state.errors.includes('error_page23_country') &&
                        <p className="error_class">{error_msg_detail}</p>
                    }
                    </Form.Group>
                </Form.Row>
                <Row className="question_btn">
                    <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={()=>pageData.prevPage('page22','page23')} >{back_btn}</Button></Col>
                    <Col sm></Col>
                    <Col sm></Col>
                    <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={()=>pageData.nextPage('page23','page24')} >{next_btn}</Button></Col>
                </Row>
            </Form>
        </>
        )
    }