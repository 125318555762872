
import 'date-fns';
import React, { Component , PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, ProgressBar } from 'react-bootstrap';
import { transformPageToValue, transformPropsForm } from '../shared/utility';

import 'react-calendar/dist/Calendar.css';
import * as QuestionComponents from "./QuestionComponents/index";
import OptionsList from "./QuestionComponents/StaticOptions";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css"

class QuestionBeta extends PureComponent {
    constructor(props) {
        const { t,i18n } = props;

        let form = transformPropsForm(props.form)


        super(props);
        // Don't do this!
        this.state = {
            total_order_amount : 19.99,
            ordered_products: [{price: "price_1Ilr4SAK3UQ6I85MXQk29NS4", quantity: 1, amount:19.99}],
            genderOptions : OptionsList.gender,
            maritalStatusOptions : OptionsList.maritalStatus,
            occupationOptions : OptionsList.occupation,
            documentOptions : OptionsList.document,
            numberOfEntriesRequestedOptions : OptionsList.numberOfEntriesRequested,
            typeOfEntryPermitOptions : OptionsList.typeOfEntryPermit,
            mainPurposeOfJourney : OptionsList.mainPurposeOfJourney,
            organization_objectives_list : OptionsList.organization_objectives,
            name_of_register_list : OptionsList.name_of_register_of_organization,
            stateListOptions : OptionsList.stateList,
            countryListOptions : OptionsList.countryList,
            currentProgressPage : 0,
            totalProgressPage : 31,
            errors : [],
            page0 : {   
                lng : "en",
                // pageStatus : true
            },
            page1 : {
                familyName : form.page1.familyName,
                birthName : form.page1.birthName,
                firstName : form.page1.firstName,
                // pageStatus : false
            },
            page2 : {
                dateOfBirth : form.page2.dateOfBirth,
                placeOfBirth : form.page2.placeOfBirth,
                countryOfBirth : form.page2.countryOfBirth,
                // pageStatus : false
            },
            page3 : {
                gender : form.page3.gender,
                maritalStatus : form.page3.maritalStatus,
                // pageStatus : false
            },
            page4 : {
                currentCitizenship : form.page4.currentCitizenship,
                orignalCitizenship : form.page4.orignalCitizenship,
                // pageStatus : false
            },
            page5 : {
                answer : form.page5.answer,
                // pageStatus : false
            },
            page6 : {
                currentOccupation : form.page6.currentOccupation,
                // pageStatus : false
            },
            page61 : {
                family_relationship: form.page61.family_relationship,
                family_name: form.page61.family_name,
                first_name: form.page61.first_name,
                date_of_birth: form.page61.date_of_birth,
                current_nationality: form.page61.current_nationality,
                travel_document: form.page61.travel_document,
                // pageStatus : false
            },
            page7 : {
                road : form.page7.road,
                houseNumber : form.page7.houseNumber,
                addressDetails : form.page7.addressDetails,
                // pageStatus : false
            },
            page8 : {
                postal_code : form.page8.postal_code,
                place : form.page8.place,
                land : form.page8.land,
                // pageStatus : false
            },
            page9 : {
                telephone : form.page9.telephone,
                email : form.page9.email,
                // pageStatus : false
            },
            page10 : {
                answer : form.page10.answer,
                // pageStatus : false
            },
            page11 : {
                entitlementResidencePermit : form.page11.entitlementResidencePermit,
                authorixationResidencePermit : form.page11.authorixationResidencePermit, 
                validDate : form.page11.validDate, 
                // pageStatus : false
            },
            page12 : {
                travelDocument : form.page12.travelDocument,
                numberOfTravelDocument : form.page12.numberOfTravelDocument, 
                identityNumber : form.page12.identityNumber, 
                // pageStatus : false
            },
            page13 : {
                issueDate : form.page13.issueDate,
                expiryDate : form.page13.expiryDate, 
                // pageStatus : false
            },
            page14 : {
                issuingState : form.page14.issuingState,
                issuedBy : form.page14.issuedBy, 
                issuedIn : form.page14.issuedIn, 
                // pageStatus : false
            },
            page15 : {  
                answer : form.page15.answer,
                // pageStatus : false
            },
            page16 : {  
                date : form.page16.date,
                // pageStatus : false
            },
            page17 : {  
                mainIssue : form.page17.mainIssue,
                other : form.page17.other,
                memberState : form.page17.memberState,
                // pageStatus : false
            },
            page18 : {  
                destination : form.page18.destination,
                numberOfEntries : form.page18.numberOfEntries,
                // pageStatus : false
            },
            page19 : {  
                arrivalDate : form.page19.arrivalDate,
                departureDate : form.page19.departureDate,
                duration : form.page19.duration,
                // pageStatus : false
            },
            page20 : {  
                answer : form.page20.answer,
                // pageStatus : false
            },
            page21 : {  
                validFrom : form.page21.validFrom,
                dateExpiry : form.page21.dateExpiry,
                // pageStatus : false
            },
            page22 : {  
                permitType  : form.page22.permitType,
                permitNumber : form.page22.permitNumber,
                selectionList : form.page22.selectionList,
                // pageStatus : false
            },
            page23 : {  
                country  : form.page23.country,
                // pageStatus : false
            },
            page24 : {  
                issuedBy  : form.page24.issuedBy,
                validFrom  : form.page24.validFrom,
                expiryDate  : form.page24.expiryDate,
                // pageStatus : false
            },
            page25 : {  
                referencePageSelection  : form.page25.referencePageSelection,
                // pageStatus : false
            },
            hideShowReferencePage : false,
            referencePage1 : {  
                familyName : form.referencePage1.familyName,
                firstName : form.referencePage1.firstName,
                gender : form.referencePage1.gender,
                dateOfBirth: form.referencePage1.dateOfBirth,
                placeOfBirth : form.referencePage1.placeOfBirth,
                nationality : form.referencePage1.nationality,
                street : form.referencePage1.street,
                houseNumber : form.referencePage1.houseNumber,
                postalCode : form.referencePage1.postalCode,
                placeCountry : form.referencePage1.placeCountry,
                land : form.referencePage1.land,
                phone : form.referencePage1.phone,
                email : form.referencePage1.email,
                diffSurName : form.referencePage1.diffSurName,
                diffFirstName : form.referencePage1.diffFirstName,
                formerName: form.referencePage1.formerName,
                otherName: form.referencePage1.otherName,

                // pageStatus : false
            },
            referencePage2 : {  
                organizationName : form.referencePage2.organizationName,
                organizationPlace : form.referencePage2.organizationPlace,
                organizationCountry : form.referencePage2.organizationCountry,
                organizationTask : form.referencePage2.organizationTask,
                organiationRegisterName : form.referencePage2.organiationRegisterName,
                registerPlace : form.referencePage2.registerPlace,
                registerNumber : form.referencePage2.registerNumber,
                contactPersonSurname : form.referencePage2.contactPersonSurname,
                contactPersonFirstname : form.referencePage2.contactPersonFirstname,
                dateOfBirth: form.referencePage2.dateOfBirth,
                gender : form.referencePage2.gender,
                nationality : form.referencePage2.nationality,
                road : form.referencePage2.road,
                houseNumber : form.referencePage2.houseNumber,
                postCode : form.referencePage2.postCode,
                location : form.referencePage2.location,
                country : form.referencePage2.country,
                phone : form.referencePage2.phone,
                email : form.referencePage2.email,
                // pageStatus : false
            },
            referencePage3 : {  
                organizationName : form.referencePage3.organizationName,
                organizationPlace : form.referencePage3.organizationPlace,
                organizationCountry : form.referencePage3.organizationCountry,
                organizationTask : form.referencePage3.organizationTask,
                organiationRegisterName : form.referencePage3.organiationRegisterName,
                registerPlace : form.referencePage3.registerPlace,
                registerNumber : form.referencePage3.registerNumber,
                hotelReservationNumber : form.referencePage3.hotelReservationNumber,
                contactPersonSurname : form.referencePage3.contactPersonSurname,
                contactPersonFirstname : form.referencePage3.contactPersonFirstname,
                road : form.referencePage3.road,
                houseNumber : form.referencePage3.houseNumber,
                postCode : form.referencePage3.postCode,
                location : form.referencePage3.location,
                country : form.referencePage3.country,
                phone : form.referencePage3.phone,
                email : form.referencePage3.email,
                // pageStatus : false
            },
            referencePage4 : {  
                organizationName: form.referencePage4.organizationName,
                familyName : form.referencePage4.familyName,
                firstName : form.referencePage4.firstName,
                gender : form.referencePage4.gender,
                dateOfBirth: form.referencePage4.dateOfBirth,
                placeOfBirth : form.referencePage4.placeOfBirth,
                nationality : form.referencePage4.nationality,
                road : form.referencePage4.road,
                houseNumber : form.referencePage4.houseNumber,
                postCode : form.referencePage4.postCode,
                location : form.referencePage4.location,
                country : form.referencePage4.country,
                phone : form.referencePage4.phone,
                email : form.referencePage4.email,
                diffSurName : form.referencePage4.diffSurName,
                diffFirstName : form.referencePage4.diffFirstName,
                otherName : form.referencePage4.otherName,
                formerName : form.referencePage4.formerName,
                // pageStatus : false
            },
            page26 : {  
                applicant : form.page26.applicant,
                thirdParty : form.page26.thirdParty,
                invitingParty : form.page26.invitingParty,
                other : form.page26.other,
                // pageStatus : false
            },
            page27 : {
                cash : form.page27.cash,
                traveler : form.page27.traveler,
                creditCard : form.page27.creditCard,
                prepaidAccommodation : form.page27.prepaidAccommodation,
                allCosts : form.page27.allCosts,
                prepaidTransportation : form.page27.prepaidTransportation,
                otherChecks : form.page27.otherChecks,
                // pageStatus : false
            },
            page28 : {
                obligorPageSelection : form.page28.obligorPageSelection,
                // pageStatus : false
            },
            page29 : {
                // pageStatus : false
            },
            obligorPage1 : {
                contactPersonSurname : form.obligorPage1.contactPersonSurname,
                contactPersonFirstname : form.obligorPage1.contactPersonFirstname,
                gender : form.obligorPage1.gender,
                dateOfBirth: form.obligorPage1.dateOfBirth,
                placeOfBirth : form.obligorPage1.placeOfBirth,
                nationality : form.obligorPage1.nationality,
                road : form.obligorPage1.road,
                houseNumber : form.obligorPage1.houseNumber,
                postCode : form.obligorPage1.postCode,
                location : form.obligorPage1.location,
                country : form.obligorPage1.country,
                phone : form.obligorPage1.phone,
                email : form.obligorPage1.email,
                diffSurName : form.obligorPage1.diffSurName,
                diffFirstName : form.obligorPage1.diffFirstName,
                otherName : form.obligorPage1.otherName,
                formerName : form.obligorPage1.formerName,
                // pageStatus : false
            },
            obligorPage2 : {
                deviatingObligor : form.obligorPage2.deviatingObligor,
                organizationPlace : form.obligorPage2.organizationPlace,
                organizationCountry : form.obligorPage2.organizationCountry,
                organizationTask : form.obligorPage2.organizationTask,
                organiationRegisterName : form.obligorPage2.organiationRegisterName,
                registerPlace : form.obligorPage2.registerPlace,
                registerNumber : form.obligorPage2.registerNumber,
                contactPersonSurname : form.obligorPage2.contactPersonSurname,
                contactPersonFirstname : form.obligorPage2.contactPersonFirstname,
                gender : form.obligorPage2.gender,
                road : form.obligorPage2.road,
                houseNumber : form.obligorPage2.houseNumber,
                postCode : form.obligorPage2.postCode,
                place : form.obligorPage2.place,
                country : form.obligorPage2.country,
                phone : form.obligorPage2.phone,
                email : form.obligorPage2.email,
                // pageStatus : false
            },
            obligorPage3 : {
                insitutionName : form.obligorPage3.insitutionName,
                contactPersonSurname : form.obligorPage3.contactPersonSurname,
                contactPersonFirstname : form.obligorPage3.contactPersonFirstname,
                gender : form.obligorPage3.gender,
                dateOfBirth: form.obligorPage3.dateOfBirth,
                placeOfBirth : form.obligorPage3.placeOfBirth,
                nationality : form.obligorPage3.nationality,
                road : form.obligorPage3.road,
                houseNumber : form.obligorPage3.houseNumber,
                postCode : form.obligorPage3.postCode,
                location : form.obligorPage3.location,
                country : form.obligorPage3.country,
                phone : form.obligorPage3.phone,
                email : form.obligorPage3.email,
                // pageStatus : false
            },
        };

        this.postData = props.postData
    }

    // select form language   
    setTotalOrderAmount = (total_order_amount) => {
        
        this.setState({
            total_order_amount   
        },()=>this.forceUpdate())

    }

    // select form language   
    changeLanguage = (lng) => {
        const { t,i18n } = this.props;
        let page0 = this.state.page0
        page0['lng'] = lng;
        this.setState({
            page0   
        },()=>this.forceUpdate())

        i18n.changeLanguage(lng)
        this.nextPage('page0','page1')
    }


    // handle change for textbox 
    handleChange = (currentPage,key) => event => {
        let value = event.target.value 
        // if(value){
           
            const category = {...this.state[currentPage]};
            category[key] = value;
            this.setState({ [currentPage]: category },()=>this.forceUpdate());
        // }
    }

    // handle change for textbox 
    handleChangeReferencePage = (reference) => {
        let page25 = this.state.page25
        page25['referencePageSelection'] = reference;
        this.setState({
            page25,
        },()=>this.forceUpdate(),
        )
        this.nextPage('page25',reference)
        // page25['pageStatus'] = false;
        this.setState({
            page25   
        },()=>this.forceUpdate())
    }

    // handle change for textbox 
    handleChangeObligorPage = (obligor) => {
        let page28 = this.state.page28
        page28['obligorPageSelection'] = obligor;
        this.setState({
            page28,
        },()=>this.forceUpdate(),
        )
        this.nextPage('page28',obligor)
        // page28['pageStatus'] = false;
        this.setState({
            page28   
        },()=>this.forceUpdate())
    }

    // handle change for calender
    handleChangeCalendar = (currentPage,key) => event => {
        let value = event
        if(value){
            const category = {...this.state[currentPage]};
            category[key] = value;
            this.setState({ [currentPage]: category },()=>this.forceUpdate());
        }
    }

    // handle change for dropdown select
    handleChangeSelect = (currentPage,key) => event => {
        let value = event
        if(value){
            const category = {...this.state[currentPage]};
            category[key] = value;
            this.setState({ [currentPage]: category },()=>this.forceUpdate());
        }
    }

    // handle change for checkbox
    handleChangeCheckbox = (currentPage,key) => event => {
        let value = event.target.checked
        const category = {...this.state[currentPage]};
        category[key] = value;
        this.setState({ [currentPage]: category },()=>this.forceUpdate());
    }

    // handle change for dropdown select
    handleChangeAnswerChoice = (currentPage,key,value,nextPage) => event => {
        if(value){
            const category = {...this.state[currentPage]};
            category[key] = value;
            this.setState({ [currentPage]: category },()=>this.forceUpdate());
            this.nextPage(currentPage,nextPage)
        }
    }

    // page object wise validation apply for all pages
    allFeildValidation = (page) => {
        let errors = []
        let pageInfo = {...this.state[page]};

        let keyArray = Object.keys(pageInfo)

        keyArray.map((val,index)=>{
            if(pageInfo[val] == ""){
                errors.push('error_'+page+'_'+val);
            }
        })
        this.setState({
            errors
        },()=>this.forceUpdate())
        return errors;
    }

    // page switch previous to next and next to previous
    dynamicSwitchPage = (currentPage,key,value) => {
        const category = {...this.state[currentPage]};
        category[key] = value;
        this.setState({ [currentPage]: category },()=>this.forceUpdate());
    }

    // next page 
    nextPage = (page,nextPage) => {
        let validation = this.allFeildValidation(page)

        if(validation.length == 0){


            this.postData(page, this.state[page])


            window.history.replaceState(null, null, `/${nextPage}`)
            // this.dynamicSwitchPage(page,'pageStatus',false)
            // this.dynamicSwitchPage(nextPage,'pageStatus',true)
            let currentProgressPage = this.state.currentProgressPage + 1

            this.setState({
                currentProgressPage
            },()=>this.forceUpdate())
        }
    }

    // next page 
    nextPageWithoutValidation = (page,nextPage) => {

            window.history.replaceState(null, null, `/${nextPage}`)
            // this.dynamicSwitchPage(page,'pageStatus',false)
            // this.dynamicSwitchPage(nextPage,'pageStatus',true)
            let currentProgressPage = this.state.currentProgressPage + 1

            this.setState({
                currentProgressPage
            },()=>this.forceUpdate())
        
    }

    // next page 
    nextPageForCheckbox = (page,nextPage) => {


        this.postData(page, this.state[page])

        window.history.replaceState(null, null, `/${nextPage}`)

        // this.dynamicSwitchPage(page,'pageStatus',false)
        // this.dynamicSwitchPage(nextPage,'pageStatus',true)
        let currentProgressPage = this.state.currentProgressPage + 1
        this.setState({
            currentProgressPage
        },()=>this.forceUpdate())
    }

    // click on back button while clear all previous form data clear
    resetFormData = (currentPage) => {
        const category = {...this.state[currentPage]};
        let errors = this.state.errors

        let keyArray = Object.keys(category)
        keyArray.map((val,index)=>{
            category[val] = "";
            // category['pageStatus'] = false;
            let value = 'error_'+currentPage+'_'+val;
            errors = errors.filter(function(item) {
                return item !== value
            })
        })
        
        this.setState({ [currentPage]: category , errors },()=>this.forceUpdate());
    }

    // back page
    prevPage = (page,backPage) => {

        window.history.replaceState(null, null, `/${page}`)
        // this.dynamicSwitchPage(page,'pageStatus',true)
        // this.dynamicSwitchPage(backPage,'pageStatus',false)
        let currentProgressPage = this.state.currentProgressPage - 1
        this.setState({
            currentProgressPage
        },()=>this.forceUpdate())
        // this.resetFormData(backPage);
    }

   render(){
     const { t, i18n, classes } = this.props;
     let state = this.state
    
    let label_progress_bar_of = t('progress_bar.of')
    let label_progress_bar_answered = t('progress_bar.answered')


    let current_url = String(window.location.pathname)

    let current_page = current_url.split("/")[1]


    this.state.currentProgressPage = 2

    let currentProgressPage = transformPageToValue(current_page)
    let totalProgressPage = this.state.totalProgressPage

   let now =  ( currentProgressPage * 100 ) / totalProgressPage


     return (
         <>
            {/* <h1>{t('home.title')}</h1> */}
            {"page0" !== current_page &&
                 <div style={{ marginTop : "-62px", marginBottom : "60px", position:"fixed", width:"100%", zIndex:"100" }}>
                    <ProgressBar variant="custom-progress-bar" now={now} label={`${now}%`} srOnly />
                    {/* <p className="progress-bar-text">{currentProgressPage+' '+label_progress_bar_of+' '+totalProgressPage+' '+label_progress_bar_answered}</p> */}
                </div>
            }
            <Container>
                {/* language list page */}
                {current_page == "page0" &&
                    QuestionComponents.page0(this)
                }
                {/* QUESTIONAIRE 1/01 */}
                {current_page == "page1" &&
                    QuestionComponents.page1(this)
                }
                {/* QUESTIONAIRE 1/02 */}
                {current_page == "page2" &&
                    QuestionComponents.page2(this)
                }
                {/* QUESTIONAIRE 1/03 */}
                {current_page == "page3" &&
                    QuestionComponents.page3(this)
                }
                {/* QUESTIONAIRE 1/04 */}
                {current_page == "page4" &&
                    QuestionComponents.page4(this)
                }
                {/* QUESTIONAIRE 1/05 */}
                {current_page == "page5" &&
                    QuestionComponents.page5(this)
                }
                {/* QUESTIONAIRE 2/01 */}
                {current_page == "page6" &&
                    QuestionComponents.page6(this)
                }
                {/* QUESTIONAIRE 2/01 */}
                {current_page == "page61" &&
                    QuestionComponents.page61(this)
                }
                {/* QUESTIONAIRE 3/01 */}
                {current_page == "page7" &&
                    QuestionComponents.page7(this)
                }
                {/* QUESTIONAIRE 3/02 */}
                {current_page == "page8" &&
                    QuestionComponents.page8(this)
                }
                {/* QUESTIONAIRE 3/03 */}
                {current_page == "page9" &&
                    QuestionComponents.page9(this)
                }
                {/* QUESTIONAIRE 3/04 */}
                {current_page == "page10" &&
                    QuestionComponents.page10(this)
                }
                {/* QUESTIONAIRE 4/01 */}
                {current_page == "page11" &&
                    QuestionComponents.page11(this)
                }
                {/* QUESTIONAIRE 5/01 */}
                {current_page == "page12" &&
                    QuestionComponents.page12(this)
                }
                {/* QUESTIONAIRE 5/02 */}
                {current_page == "page13" &&
                    QuestionComponents.page13(this)
                }
                {/* QUESTIONAIRE 5/03 */}
                {current_page == "page14" &&
                     QuestionComponents.page14(this)
                }
                {/* QUESTIONAIRE 6/01 */}
                {current_page == "page15" &&
                    QuestionComponents.page15(this)
                }
                {/* QUESTIONAIRE 6/02 */}
                {current_page == "page16" &&
                    QuestionComponents.page16(this)
                }
                {/* QUESTIONAIRE 7/01 */}
                {current_page == "page17" &&
                    QuestionComponents.page17(this)
                }
                {/* QUESTIONAIRE 7/02 */}
                {current_page == "page18" &&
                    QuestionComponents.page18(this)
                }
                {/* QUESTIONAIRE 7/03 */}
                {current_page == "page19" &&
                    QuestionComponents.page19(this)
                }
                {/* QUESTIONAIRE 7/04 */}
                {current_page == "page20" &&
                    QuestionComponents.page20(this)
                }
                {/* QUESTIONAIRE 7/05 */}
                {current_page == "page21" &&
                    QuestionComponents.page21(this)
                }
                {/* QUESTIONAIRE 8/01 */}
                {current_page == "page22" &&
                    QuestionComponents.page22(this)
                }
                {/* QUESTIONAIRE 8/02 */}
                {current_page == "page23" &&
                    QuestionComponents.page23(this)
                }
                {/* QUESTIONAIRE 8/03 */}
                {current_page == "page24" &&
                    QuestionComponents.page24(this)
                }
                {/* QUESTIONAIRE 9/01 */}
                {current_page == "page25" &&
                    QuestionComponents.page25(this)
                }
                {/* QUESTIONAIRE 10/01 */}
                {current_page == "referencePage1" &&
                    QuestionComponents.referencePage1(this)
                }
                {/* QUESTIONAIRE 10/02 */}
                {current_page == "referencePage2" &&
                    QuestionComponents.referencePage2(this)
                }
                {/* QUESTIONAIRE 10/03 */}
                {current_page == "referencePage3" &&
                    QuestionComponents.referencePage3(this)
                }
                {/* QUESTIONAIRE 10/04 */}
                {current_page == "referencePage4" &&
                    QuestionComponents.referencePage4(this)
                }

                {/* QUESTIONAIRE 11/01 */}
                {current_page == "page26" &&
                    QuestionComponents.page26(this)
                }
                {/* QUESTIONAIRE 11/02 */}
                {current_page == "page27" &&
                    QuestionComponents.page27(this)
                }
                {/* QUESTIONAIRE 12/01 */}
                {current_page == "obligorPage1" &&
                    QuestionComponents.obligorPage1(this)
                }
                {/* QUESTIONAIRE 12/02 */}
                {current_page == "obligorPage2" &&
                    QuestionComponents.obligorPage2(this)
                }
                {/* QUESTIONAIRE 12/03 */}
                {current_page == "obligorPage3" &&
                    QuestionComponents.obligorPage3(this)
                }
                {/* QUESTIONAIRE 13/01 */}
                {current_page == "page28" &&
                    QuestionComponents.page28(this)
                }
                {/* QUESTIONAIRE 13/01 */}
                {current_page == "page29" &&
                    QuestionComponents.page29(this)
                }
                 {/* QUESTIONAIRE 13/01 */}
                 {current_page == "page30" &&
                    QuestionComponents.Page30(this)
                }
                {/* QUESTIONAIRE 13/01 */}
                {current_page == "page31" &&
                    QuestionComponents.Page31(this)
                }
                {/* QUESTIONAIRE 13/01 */}
                {current_page == "page32" &&
                    QuestionComponents.Page32(this)
                }
                
            </Container>
       </>
     )
   };
};

export default withTranslation()(QuestionBeta);