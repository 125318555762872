import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button } from 'react-bootstrap';

export const page0 = (pageData) => {
        let state = pageData.state
        const { t, i18n, classes } = pageData.props;
        
        return(
            <>
                <p className="question-title">Please choose a language to continue</p>
                <Container className="mt-5">
                    <Row>
                        <Col sm>
                            <button type="button" onClick={()=>pageData.changeLanguage('de')} className="btn btn-outline-primary choice-button mb-4">
                                Deutsch
                            </button>
                            {/* <Button 
                                variant={state.page0.lng == 'en' ? "primary" : "light"}
                                onClick={()=>pageData.changeLanguage('en')}
                                style={{ marginRight : '20px' }}
                                size="lg" block
                                >english
                            </Button> */}
                        </Col>
                        <Col sm>
                            <button type="button" onClick={()=>pageData.changeLanguage('en')} className="btn btn-outline-primary choice-button mb-4">
                                English
                            </button>
                            {/* <Button 
                                variant={state.page0.lng == 'fr' ? "primary" : "light"}
                                onClick={()=>pageData.changeLanguage('fr')}
                                size="lg"
                                >french
                            </Button> */}
                        </Col>
                        <Col sm>
                            {/* languagecode spenish- es */}
                            <button type="button" onClick={()=>pageData.changeLanguage('es')} className="btn btn-outline-primary choice-button mb-4">
                            Español
                            </button>
                            {/* <Button 
                                variant={state.page0.lng == 'fr' ? "primary" : "light"}
                                onClick={()=>pageData.changeLanguage('fr')}
                                size="lg"
                                >french
                            </Button> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm>
                            <button type="button" onClick={()=>pageData.changeLanguage('fr')} className="btn btn-outline-primary choice-button mb-4">
                            Français
                            </button>
                            {/* <Button 
                                variant={state.page0.lng == 'en' ? "primary" : "light"}
                                onClick={()=>pageData.changeLanguage('en')}
                                style={{ marginRight : '20px' }}
                                size="lg" block
                                >english
                            </Button> */}
                        </Col>
                        <Col sm>
                            <button type="button" onClick={()=>pageData.changeLanguage('zh')} className="btn btn-outline-primary choice-button mb-4">
                            中國人
                            </button>
                            {/* <Button 
                                variant={state.page0.lng == 'fr' ? "primary" : "light"}
                                onClick={()=>pageData.changeLanguage('fr')}
                                size="lg"
                                >french
                            </Button> */}
                        </Col>
                        <Col sm>
                            {/* languagecode spenish- es */}
                            <button type="button" onClick={()=>pageData.changeLanguage('ar')} className="btn btn-outline-primary choice-button mb-4">
                            عربى
                            </button>
                            {/* <Button 
                                variant={state.page0.lng == 'fr' ? "primary" : "light"}
                                onClick={()=>pageData.changeLanguage('fr')}
                                size="lg"
                                >french
                            </Button> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm>
                            <button type="button" onClick={()=>pageData.changeLanguage('tr')} className="btn btn-outline-primary choice-button mb-4">
                            Türk
                            </button>
                            {/* <Button 
                                variant={state.page0.lng == 'en' ? "primary" : "light"}
                                onClick={()=>pageData.changeLanguage('en')}
                                style={{ marginRight : '20px' }}
                                size="lg" block
                                >english
                            </Button> */}
                        </Col>
                    </Row>
                </Container>
                <Container style={{ marginTop : '20px' }}>
                    <Row>
                        {/* <Col sm><Button variant="primary"  disabled={true} >{t('common_btn.back')}</Button></Col> */}
                        {/* <Col sm><Button variant="primary" onClick={()=>pageData.nextPage('page0','page1')} >next</Button></Col> */}
                    </Row>
                </Container>
            </>
        )
    }