import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DatePicker from "react-datepicker";


export const page61 = (pageData) => {

    let currentDate = new Date()

        let state = pageData.state
        const { t, i18n, classes } = pageData.props;

        let back_btn = t('common_btn.back')
        let next_btn = t('common_btn.next')
        let error_msg_detail = t('error_msg.error_msg_detail')
        let label_page_title = t('question.page_61.page_title')
        let label_family_relationship = t('question.page_61.family_relationship')
        let label_family_name = t('question.page_61.family_name')
        let label_first_name = t('question.page_61.first_name')
        let label_date_of_birth = t('question.page_61.date_of_birth')
        let label_current_nationality = t('question.page_61.current_nationality')
        let label_travel_document = t('question.page_61.travel_document')

        let family_relationship_options = t('family_relationship_options', {returnObjects:true})

        return(
            <>
                <p className="question-title">{label_page_title}</p>
                <Form>
                <Form.Row className="customedate">
                <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_family_relationship}</Form.Label>
                        <Select
                        value={state.page61.family_relationship}
                        onChange={pageData.handleChangeSelect('page61', 'family_relationship')}
                        options={family_relationship_options}
                        />
                       
                        {state.errors.includes('error_page61_family_relationship') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_first_name}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('page61', 'first_name')}
                            value={state.page61.first_name}
                        />
                        {state.errors.includes('error_page61_first_name') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>

                <Form.Group controlId="formGridPassword">
                        <Form.Label>{label_family_name}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('page61', 'family_name')}
                            value={state.page61.family_name}
                        />
                        {state.errors.includes('error_page61_family_name') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    
                    
                    
                <Form.Row className="customedate">
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>{label_date_of_birth}</Form.Label>
                        <DatePicker
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            maxDate={currentDate}
                            selected={state.page61.date_of_birth}
                            onSelect={pageData.handleChangeCalendar('page61', 'date_of_birth')} //when day is clicked
                            onChange={pageData.handleChangeCalendar('page61', 'date_of_birth')} //only when value has changed
                        />
                        {state.errors.includes('error_page61_date_of_birth') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>

                    <Form.Group  as={Col} controlId="formGridAddress1">
                    <Form.Label>{label_current_nationality}</Form.Label>
                    <Select
                        value={state.page61.current_nationality}
                        onChange={pageData.handleChangeSelect('page61', 'current_nationality')}
                        options={state.countryListOptions}
                        />
                    {state.errors.includes('error_page61_current_nationality') &&
                        <p className="error_class">{error_msg_detail}</p>
                    }
                </Form.Group>
                </Form.Row>


                <Form.Group controlId="formGridPassword">
                        <Form.Label>{label_travel_document}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('page61', 'travel_document')}
                            value={state.page61.travel_document}
                        />
                        {state.errors.includes('error_page61_travel_document') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    
                 
                    
                    
                    
                    
                    <Row className="question_btn">
                        <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={()=>pageData.prevPage('page5','page61')} >{back_btn}</Button></Col>
                        <Col sm></Col>
                        <Col sm></Col>
                        <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={()=>pageData.nextPage('page61','page7')} >{next_btn}</Button></Col>
                    </Row>
                </Form>
            </>
        )
    }