import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

export const referencePage3 = (pageData) => {
    let state = pageData.state
    const { t, i18n, classes } = pageData.props;

    let back_btn = t('common_btn.back')
    let next_btn = t('common_btn.next')
    let label_page_title = t('question.refrence.page_3.page_title')
    let label_name_organization = t('question.refrence.page_3.name_organization')
    let label_headquater_organization = t('question.refrence.page_3.headquater_organization')
    let label_organization_headquaters = t('question.refrence.page_3.organization_headquaters')
    let label_task_organization = t('question.refrence.page_3.task_organization')
    let label_name_organiation = t('question.refrence.page_3.name_organiation')
    let label_place_register = t('question.refrence.page_3.place_register')
    let label_register_number = t('question.refrence.page_3.register_number')
    let label_hotel_number = t('question.refrence.page_3.hotel_number')
    let label_surname_contact_person = t('question.refrence.page_3.surname_contact_person')
    let label_first_name_contact_person = t('question.refrence.page_3.first_name_contact_person')
    let label_road = t('question.refrence.page_3.road')
    let label_house_number = t('question.refrence.page_3.house_number')
    let label_post_code = t('question.refrence.page_3.post_code')
    let label_location = t('question.refrence.page_3.location')
    let label_country = t('question.refrence.page_3.country')
    let label_phone = t('question.refrence.page_3.phone')
    let label_email = t('question.refrence.page_3.email')
    let error_msg_detail = t('error_msg.error_msg_detail')

    let organization_objectives_list = t('organization_objectives', {returnObjects:true})
    let name_of_register_list = t('name_of_register_of_organization', {returnObjects:true})

    if(state.referencePage3.dateOfBirth)state.referencePage3.dateOfBirth = new Date(state.referencePage3.dateOfBirth)
    if(state.referencePage3.gender && !state.referencePage3.gender.label)state.referencePage3.gender = JSON.parse(state.referencePage3.gender)
    if(state.referencePage3.nationality && !state.referencePage3.nationality.label)state.referencePage3.nationality = JSON.parse(state.referencePage3.nationality)
    if(state.referencePage3.country && !state.referencePage3.country.label)state.referencePage3.country = JSON.parse(state.referencePage3.country)
    if(state.referencePage3.organizationCountry && !state.referencePage3.organizationCountry.label)state.referencePage3.organizationCountry = JSON.parse(state.referencePage3.organizationCountry)
    if(state.referencePage3.organizationTask && !state.referencePage3.organizationTask.label)state.referencePage3.organizationTask = JSON.parse(state.referencePage3.organizationTask)
    if(state.referencePage3.organiationRegisterName && !state.referencePage3.organiationRegisterName.label)state.referencePage3.organiationRegisterName = JSON.parse(state.referencePage3.organiationRegisterName)


    return (
        <>
            <p className="question-title">{label_page_title}</p>
            <Form>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_name_organization}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage3', 'organizationName')}
                            value={state.referencePage3.organizationName}
                        />
                        {state.errors.includes('error_referencePage3_organizationName') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_headquater_organization}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage3', 'organizationPlace')}
                            value={state.referencePage3.organizationPlace}
                        />
                        {state.errors.includes('error_referencePage3_organizationPlace') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_organization_headquaters}</Form.Label>
                        <Select
                            onChange={pageData.handleChangeSelect('referencePage3', 'organizationCountry')}
                            value={state.referencePage3.organizationCountry}
                            options={state.countryListOptions}
                        />
                        {state.errors.includes('error_referencePage3_organizationCountry') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_task_organization}</Form.Label>
                        <Select
                            onChange={pageData.handleChangeSelect('referencePage3', 'organizationTask')}
                            value={state.referencePage3.organizationTask}
                            options={organization_objectives_list}
                        />
                        {state.errors.includes('error_referencePage3_organizationTask') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_name_organiation}</Form.Label>
                        <Select
                            onChange={pageData.handleChangeSelect('referencePage3', 'organiationRegisterName')}
                            value={state.referencePage3.organiationRegisterName}
                            options={name_of_register_list}
                        />
                        {state.errors.includes('error_referencePage3_organiationRegisterName') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_place_register}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage3', 'registerPlace')}
                            value={state.referencePage3.registerPlace}
                        />
                        {state.errors.includes('error_referencePage3_registerPlace') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_register_number}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage3', 'registerNumber')}
                            value={state.referencePage3.registerNumber}
                        />
                        {state.errors.includes('error_referencePage3_registerNumber') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_hotel_number}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage3', 'hotelReservationNumber')}
                            value={state.referencePage3.hotelReservationNumber}
                        />
                        {state.errors.includes('error_referencePage3_hotelReservationNumber') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_surname_contact_person}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage3', 'contactPersonSurname')}
                            value={state.referencePage3.contactPersonSurname}
                        />
                        {state.errors.includes('error_referencePage3_contactPersonSurname') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_first_name_contact_person}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage3', 'contactPersonFirstname')}
                            value={state.referencePage3.contactPersonFirstname}
                        />
                        {state.errors.includes('error_referencePage3_contactPersonFirstname') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_road}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage3', 'road')}
                            value={state.referencePage3.road}
                        />
                        {state.errors.includes('error_referencePage3_road') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_house_number}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage3', 'houseNumber')}
                            value={state.referencePage3.houseNumber}
                        />
                        {state.errors.includes('error_referencePage3_houseNumber') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_post_code}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage3', 'postCode')}
                            value={state.referencePage3.postCode}
                        />
                        {state.errors.includes('error_referencePage3_postCode') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_location}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage3', 'location')}
                            value={state.referencePage3.location}
                        />
                        {state.errors.includes('error_referencePage3_location') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    {/* <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_country}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage3', 'country')}
                            value={state.referencePage3.country}
                        />
                        {state.errors.includes('error_referencePage3_country') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group> */}
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_country}</Form.Label>
                        <Select
                            onChange={pageData.handleChangeSelect('referencePage3', 'country')}
                            value={state.referencePage3.country}
                            options={state.countryListOptions}
                        />
                        {state.errors.includes('error_referencePage3_organizationCountry') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    {/* <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_country}</Form.Label>
                        <Select
                            onChange={pageData.handleChangeSelect('referencePage3', 'nationality')}
                            value={state.referencePage3.nationality}
                            options={state.countryListOptions}
                        />
                        {state.errors.includes('error_referencePage3_nationality') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group> */}
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_phone}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage3', 'phone')}
                            value={state.referencePage3.phone}
                        />
                        {state.errors.includes('error_referencePage3_phone') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_email}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage3', 'email')}
                            value={state.referencePage3.email}
                        />
                        {state.errors.includes('error_referencePage3_email') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Row className="question_btn">
                    <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={() => pageData.prevPage('page25', 'referencePage3')} >{back_btn}</Button></Col>
                    <Col sm></Col>
                    <Col sm></Col>
                    <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={() => pageData.nextPage('referencePage3', 'page26')} >{next_btn}</Button></Col>
                </Row>
            </Form>
        </>
    )
}