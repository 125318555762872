import './App.css';
import { useDispatch, useSelector, connect } from 'react-redux';
import Layout from './hoc/Layout'
import Navigation from './hoc/Navigation'
import BottomNavigation from './hoc/BottomNavigation/BottomNavigation'
import React, {useEffect, Suspense} from 'react';
import AllRoutes from './routing/AllRoutes'
import { validateAuthenticationAndGetData, validateTokenAndGetData, postQuestionForm } from './redux/actions/index'

let windowHeight = null;

let validateUserState = false;

const App = props => {


  const dispatch = useDispatch();



  useEffect(() => {
    windowHeight = (window.screen.height-200) + "px"
  })


  useEffect(() => {

    console.log("USE EFFECT AUTH ==> " + validateUserState)

    if(!validateUserState){

      console.log("!validateUserState ==> " + validateUserState)

      dispatch(validateAuthenticationAndGetData());
    }
}, [props.token]);


useEffect(() => {

  console.log(" Else ==> " + validateUserState)
  console.log(" props.form ==> " + props.form)

  if(props.form && props.v2)window.open("/page0","_self")

  
  //window.history.replaceState(null, null, `/page0`)
}, [props.form, props.v2])


const postData = (page, object) => {


  let stringifyJSON = `{"${page}":${JSON.stringify(object)}}`


  dispatch(postQuestionForm(stringifyJSON))

}


const validateUser = (id) => {

  console.log("USE EFFECT TOKEN")

  validateUserState = true;

  dispatch(validateTokenAndGetData(id))

}


  return (
    <Suspense fallback={(<div>Loading</div>)}>
      
      <div style={{height:windowHeight, marginTop:"140px"}} className="App">
        <Navigation />
        <Layout>
              <Suspense><AllRoutes form={props.form} postData={(page, object) => postData(page, object)} validateUser={(id) => validateUser(id)}/></Suspense>
        </Layout>
        <BottomNavigation></BottomNavigation>
    </div>
    
    </Suspense>
  );
}

const mapStateToProps = state => {

  return {
    token: state.auth.token,
    redirect_path: state.auth.redirect_path,
    form: state.question.form,
    v2: state.question.v2,
    id_of_answer: state.question.id_of_answer
  };
};

const mapDispatchToProps = dispatch => {

  return {
    // onSignIn: () => dispatch(getNextQuestion("5f69aa9d8ad60b00172ad048")),
    onValidateAuthentication: () => dispatch(validateAuthenticationAndGetData()),

  };
};

export default
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)