import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';

export const page25 = (pageData) => {
        let state = pageData.state
        const { t, i18n, classes } = pageData.props;

        let back_btn = t('common_btn.back')
        let label_page_title = t('question.page_25.page_title')
        let reference_1 = t('question.page_25.reference_1')
        let reference_2 = t('question.page_25.reference_2')
        let reference_3 = t('question.page_25.reference_3')
        let reference_4 = t('question.page_25.reference_4')
        let reference_5 = t('question.page_25.reference_5')
        let reference_6 = t('question.page_25.reference_6')
        let reference_7 = t('question.page_25.reference_7')

        return(
            <>
                <Form>
                <p className="question-title">{label_page_title}</p> 

                    <Form.Row>
                        <Col style={{textAlign:"center"}} sm>
                                <button type="button" onClick={()=>pageData.handleChangeReferencePage('referencePage1')} class="btn btn-outline-primary choice-button mb-4">
                                    {reference_1}
                                </button>
                            </Col>
                            <Col style={{textAlign:"center"}} sm>
                                <button type="button" onClick={()=>pageData.handleChangeReferencePage('referencePage2')} class="btn btn-outline-primary choice-button mb-4">
                                    {reference_2}
                                </button>
                            </Col>
                            <Col style={{textAlign:"center"}} sm>
                                <button type="button" onClick={()=>pageData.handleChangeReferencePage('referencePage3')} class="btn btn-outline-primary choice-button mb-4">
                                    {reference_3}
                                </button>
                            </Col>
                    </Form.Row>
                    <Form.Row>
                            <Col style={{textAlign:"center"}} sm>
                                <button type="button" onClick={()=>pageData.handleChangeReferencePage('referencePage4')} class="btn btn-outline-primary choice-button mb-4">
                                    {reference_4}
                                </button>
                            </Col>
                            <Col style={{textAlign:"center"}} sm>
                                <button type="button" onClick={()=>pageData.handleChangeReferencePage('referencePage1')} class="btn btn-outline-primary choice-button mb-4">
                                    {reference_5}
                                </button>
                            </Col>
                            <Col style={{textAlign:"center"}} sm>
                                <button type="button" onClick={()=>pageData.handleChangeReferencePage('referencePage1')} class="btn btn-outline-primary choice-button mb-4">
                                    {reference_6}
                                </button>
                            </Col>
                    </Form.Row>
                    <Form.Row>
                            <Col style={{textAlign:"center"}} sm>
                                <button type="button" onClick={()=>pageData.handleChangeReferencePage('referencePage4')} class="btn btn-outline-primary choice-button mb-4">
                                    {reference_7}
                                </button>
                            </Col>
                    </Form.Row>
                </Form>
                 <Row className="question_btn">
                    <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={()=>pageData.prevPage('page24','page25')} >{back_btn}</Button></Col>
                    <Col sm></Col>
                    <Col sm></Col>
                    <Col sm></Col>
                </Row>
            </>
        )
    }