import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';

export const page27 = (pageData) => {
        let state = pageData.state
        const { t, i18n, classes } = pageData.props;

        let back_btn = t('common_btn.back')
        let next_btn = t('common_btn.next')
        let label_page_title = t('question.page_27.page_title')
        let label_cash = t('question.page_27.cash')
        let label_traveler_checks = t('question.page_27.traveler_checks')
        let label_credit_card = t('question.page_27.credit_card')
        let label_prepaid_accommodation = t('question.page_27.prepaid_accommodation')
        let label_assumption_of_all_costs = t('question.page_27.assumption_of_all_costs')
        let label_prepaid_transportation = t('question.page_27.prepaid_transportation')
        let label_other_checks = t('question.page_27.other_checks')

        return(
            <>
                <p className="question-title">{label_page_title}</p>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="validationFormik1" style={{ textAlign : "start" }}>
                        <Form.Check
                            required
                            name="terms"
                            label={label_cash}
                            onChange={pageData.handleChangeCheckbox('page27','cash')} 
                            checked={state.page27.cash} 
                            id="validationFormik1"
                        />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="validationFormik2" style={{ textAlign : "start" }}>
                        <Form.Check
                            required
                            name="terms"
                            label={label_traveler_checks}
                            onChange={pageData.handleChangeCheckbox('page27','traveler')} 
                            checked={state.page27.traveler} 
                            id="validationFormik2"
                        />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="validationFormik3" style={{ textAlign : "start" }}>
                        <Form.Check
                            required
                            name="terms"
                            label={label_credit_card}
                            onChange={pageData.handleChangeCheckbox('page27','creditCard')} 
                            checked={state.page27.creditCard} 
                            id="validationFormik3"
                        />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="validationFormik4" style={{ textAlign : "start" }}>
                        <Form.Check
                            required
                            name="terms"
                            label={label_prepaid_accommodation}
                            onChange={pageData.handleChangeCheckbox('page27','prepaidAccommodation')} 
                            checked={state.page27.prepaidAccommodation} 
                            id="validationFormik4"
                        />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="validationFormik5" style={{ textAlign : "start" }}>
                        <Form.Check
                            required
                            name="terms"
                            label={label_assumption_of_all_costs}
                            onChange={pageData.handleChangeCheckbox('page27','allCosts')} 
                            checked={state.page27.allCosts} 
                            id="validationFormik5"
                        />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="validationFormik6" style={{ textAlign : "start" }}>
                        <Form.Check
                            required
                            name="terms"
                            label={label_prepaid_transportation}
                            onChange={pageData.handleChangeCheckbox('page27','prepaidTransportation')} 
                            checked={state.page27.prepaidTransportation} 
                            id="validationFormik6"
                        />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="validationFormik7" style={{ textAlign : "start" }}>
                        <Form.Check
                            required
                            name="terms"
                            label={label_other_checks}
                            onChange={pageData.handleChangeCheckbox('page27','otherChecks')} 
                            checked={state.page27.otherChecks} 
                            id="validationFormik7"
                        />
                        </Form.Group>
                    </Form.Row>
                    <Row className="question_btn">
                        <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={()=>pageData.prevPage('page26','page27')} >{back_btn}</Button></Col>
                        <Col sm></Col>
                        <Col sm></Col>
                        <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={()=>pageData.nextPageForCheckbox('page27','page28')} >{next_btn}</Button></Col>
                    </Row>
                </Form>
            </>
        )
    }