import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from "react-datepicker";

export const obligorPage1 = (pageData) => {
    let state = pageData.state
    const { t, i18n, classes } = pageData.props;

    const currentDate = new Date()

    let back_btn = t('common_btn.back')
    let next_btn = t('common_btn.next')
    let label_page_title = t('question.Obligor.page_1.page_title')
    let label_surname = t('question.Obligor.page_1.surname')
    let label_first_name = t('question.Obligor.page_1.first_name')
    let label_gender = t('question.Obligor.page_1.gender')
    let label_date_of_birth = t('question.Obligor.page_1.date_of_birth')
    let label_place_of_birth = t('question.Obligor.page_1.place_of_birth')
    let label_nationality = t('question.Obligor.page_1.nationality')
    let label_road = t('question.Obligor.page_1.road')
    let label_house_number = t('question.Obligor.page_1.house_number')
    let label_post_code = t('question.Obligor.page_1.post_code')
    let label_location = t('question.Obligor.page_1.location')
    let label_country = t('question.Obligor.page_1.country')
    let label_phone = t('question.Obligor.page_1.phone')
    let label_email = t('question.Obligor.page_1.email')
    let label_different_surname = t('question.Obligor.page_1.different_surname')
    let label_different_firstname = t('question.Obligor.page_1.different_firstname')
    let label_other_name = t('question.Obligor.page_1.other_name')
    let label_former_name = t('question.Obligor.page_1.former_name')
    let error_msg_detail = t('error_msg.error_msg_detail')


    let genderOptions = t('gender', {returnObjects:true})


    
    if(state.obligorPage1.dateOfBirth)state.obligorPage1.dateOfBirth = new Date(state.obligorPage1.dateOfBirth)
    if(state.obligorPage1.gender && !state.obligorPage1.gender.label)state.obligorPage1.gender = JSON.parse(state.obligorPage1.gender)
    if(state.obligorPage1.nationality && !state.obligorPage1.nationality.label)state.obligorPage1.nationality = JSON.parse(state.obligorPage1.nationality)
    if(state.obligorPage1.country && !state.obligorPage1.country.label)state.obligorPage1.country = JSON.parse(state.obligorPage1.country)
    if(state.obligorPage1.gender && !state.obligorPage1.gender.label)state.obligorPage1.gender = JSON.parse(state.obligorPage1.gender)
    if(state.obligorPage1.gender && !state.obligorPage1.gender.label)state.obligorPage1.gender = JSON.parse(state.obligorPage1.gender)
    if(state.obligorPage1.gender && !state.obligorPage1.gender.label)state.obligorPage1.gender = JSON.parse(state.obligorPage1.gender)
    if(state.obligorPage1.gender && !state.obligorPage1.gender.label)state.obligorPage1.gender = JSON.parse(state.obligorPage1.gender)


    return (
        <>
            <p className="question-title">{label_page_title}</p>
            <Form>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_surname}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage1', 'contactPersonSurname')}
                            value={state.obligorPage1.contactPersonSurname}
                        />
                        {state.errors.includes('error_obligorPage1_contactPersonSurname') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_first_name}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage1', 'contactPersonFirstname')}
                            value={state.obligorPage1.contactPersonFirstname}
                        />
                        {state.errors.includes('error_obligorPage1_contactPersonFirstname') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_gender}</Form.Label>
                        <Select
                            onChange={pageData.handleChangeSelect('obligorPage1', 'gender')}
                            value={state.obligorPage1.gender}
                            options={genderOptions}
                        />
                        {state.errors.includes('error_obligorPage1_gender') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>{label_date_of_birth}</Form.Label>
                            <DatePicker
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                maxDate={currentDate}
                                selected={state.obligorPage1.dateOfBirth}
                                onSelect={pageData.handleChangeCalendar('obligorPage1', 'dateOfBirth')} //when day is clicked
                                onChange={pageData.handleChangeCalendar('obligorPage1', 'dateOfBirth')} //only when value has changed
                            />
                              {state.errors.includes('error_obligorPage1_dateOfBirth') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                        </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_place_of_birth}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage1', 'placeOfBirth')}
                            value={state.obligorPage1.placeOfBirth}
                        />
                        {state.errors.includes('error_obligorPage1_placeOfBirth') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_nationality}</Form.Label>
                        <Select
                            onChange={pageData.handleChangeSelect('obligorPage1', 'nationality')}
                            value={state.obligorPage1.nationality}
                            options={state.countryListOptions}
                        />
                        {state.errors.includes('error_obligorPage1_nationality') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_road}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage1', 'road')}
                            value={state.obligorPage1.road}
                        />
                        {state.errors.includes('error_obligorPage1_road') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_house_number}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage1', 'houseNumber')}
                            value={state.obligorPage1.houseNumber}
                        />
                        {state.errors.includes('error_obligorPage1_houseNumber') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_post_code}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage1', 'postCode')}
                            value={state.obligorPage1.postCode}
                        />
                        {state.errors.includes('error_obligorPage1_postCode') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_location}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage1', 'location')}
                            value={state.obligorPage1.location}
                        />
                        {state.errors.includes('error_obligorPage1_location') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    {/* <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_country}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage1', 'country')}
                            value={state.obligorPage1.country}
                        />
                        {state.errors.includes('error_obligorPage1_country') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group> */}
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_country}</Form.Label>
                        <Select
                            onChange={pageData.handleChangeSelect('obligorPage1', 'country')}
                            value={state.obligorPage1.country}
                            options={state.countryListOptions}
                        />
                        {state.errors.includes('error_obligorPage1_organizationCountry') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_phone}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage1', 'phone')}
                            value={state.obligorPage1.phone}
                        />
                        {state.errors.includes('error_obligorPage1_phone') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_email}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage1', 'email')}
                            value={state.obligorPage1.email}
                        />
                        {state.errors.includes('error_obligorPage1_email') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_different_surname}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage1', 'diffSurName')}
                            value={state.obligorPage1.diffSurName}
                        />
                        {state.errors.includes('error_obligorPage1_diffSurName') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_different_firstname}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage1', 'diffFirstName')}
                            value={state.obligorPage1.diffFirstName}
                        />
                        {state.errors.includes('error_obligorPage1_diffFirstName') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_other_name}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage1', 'otherName')}
                            value={state.obligorPage1.otherName}
                        />
                        {state.errors.includes('error_obligorPage1_otherName') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_former_name}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage1', 'formerName')}
                            value={state.obligorPage1.formerName}
                        />
                        {state.errors.includes('error_obligorPage1_formerName') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Row className="question_btn">
                    <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={() => pageData.prevPage('page28', 'obligorPage1')} >{back_btn}</Button></Col>
                    <Col sm></Col>
                    <Col sm></Col>
                    <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={()=>pageData.nextPage('obligorPage1','page29')}  >{next_btn}</Button></Col>
                </Row>
            </Form>
        </>
    )
}