import React, { useState, useEffect } from 'react';
import './BottomNavigation.css'


const BottomNavigation = (props) => {

    const [ lang, setLang ] =  useState("en");


    useEffect(() => {
        let path = window.location.pathname

        const currentLang = path.substring(0,3);
        setLang(currentLang);
    }, []);


    var width = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    let mobile = width < 800


    const clickHandler = (i) => {

        props.clicked(i)
    }

  
    return (
        <div className="mother_container_bottom_nav">
            <div className="main_container_bottom_nav">
            <span></span>
            <a href="#">{lang === "/en" ? "Privacy" : "Datenschutz"}</a>
            <a href="#">{lang === "/en" ? "Imprint" : "Impressum"}</a>

            </div>
        </div>
            
    );
};

export default
  BottomNavigation;