import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import {Link, Redirect } from "react-router-dom";
import { confirmResetPassword, sendResetPasswordMail } from '../redux/actions/index'

const NewPassword = (props) => {


    const { onConfirmResetPassword, token, error, redirectTo, error_confirm_password } = props;

    const [passwordOne, setPasswordOne] = useState("");
    const [passwordTwo, setPasswordTwo] = useState("");
    const [passwordEqual, setPasswordEqual] = useState(true);


    const clickHandler = (e) => {

        e.preventDefault()

        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');

        if(passwordOne === passwordTwo && passwordOne.length > 0 && passwordTwo.length > 0){
            onConfirmResetPassword(token, passwordOne )
        }else{
            setPasswordEqual(false)
        }


    }

    return (

        <>{  redirectTo && redirectTo != "/" ? (
            <Redirect to={{ pathname: redirectTo }} />
        ) : (
            <>
            {error_confirm_password && <p>{props.lang === "EN" ? "Something went wrong, please try again!" : "Etwas ist schiefgelaufen, bitte versuche es erneut!"}</p>}
            <div className="auth-wrapper align-self-center mt-5">
                <div className="auth-inner new-password">
                    <form>
                        <h3 className="mb-5">{props.lang === "EN" ? "New Password" : "Neues Passwort"}</h3>
                        <div className="form-group mt-5 mb-5">
                            <label>{props.lang === "EN" ? "Password" : "Passwort"}</label>
                            <input
                                type="password"
                                className="form-control"
                                placeholder=""
                                value={passwordOne}
                                onChange={e => {
                                    setPasswordOne(e.target.value);
                                }}
                            />
                        </div>
                        <div className="form-group pb-3">
                            <label>{props.lang === "EN" ? "Confirm Password" : "Passwort bestätigen"}</label>
                            <input
                                type="password"
                                className="form-control"
                                placeholder=""
                                value={passwordTwo}
                                onChange={e => {
                                    setPasswordTwo(e.target.value);
                                }}
                            />
                        </div>
                        {passwordEqual ? null : <p className="warning-label">{props.lang === "EN" ? "Please check your passwords" : "Bitte überprüfe deine eingegebenen Passwörter"}</p>}
                        <button onClick={(e) => clickHandler(e)} type="button" className="btn btn-primary btn-block blue-btn mt-5">
                            {props.lang === "EN" ? "Confirm " : "Bestätigen"}
                        </button>
                    </form>
                </div>
            </div>
        </>
        )}
        </>
        
    );
}


const mapStateToProps = state => {

    return {
        token: state.auth.token,
        error: state.auth.error_code,
        redirectTo: state.auth.redirect_path,
        question: state.question.question,
        error_confirm_password: state.auth.error_confirm_password
    };
};

const mapDispatchToProps = dispatch => {

    return {
        onConfirmResetPassword: (token, password) => dispatch(confirmResetPassword(token, password)),
    };
};

export default
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(NewPassword)