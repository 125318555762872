import React, {useState} from 'react';
import CountrySelect from 'react-bootstrap-country-select';
import 'bootstrap/dist/css/bootstrap.css'; // or include from a CDN
import 'react-bootstrap-country-select/dist/react-bootstrap-country-select.css'; 
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import Select from 'react-select'
import countryList from 'react-select-country-list'

function Result() {

    const [country, selectCountry] = useState("");

    const [country2, selectCountry2] = useState("");


    const changeHandler = (value) => {

        console.log(JSON.stringify(countryList().getData()))
        selectCountry2(value)
    }


    return (
        <div>
                 {/* <CountryDropdown
          value={country}
          onChange={(val) => selectCountry(val)}
         /> */}

        <Select
            options={countryList().getData()}
            value={country2}
            onChange={(value) => changeHandler(value)}
        />

        <div>lol</div>

        </div>
    );
}

export default Result;