import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';

import thank_you_image from '../../assets/img/thank_you.gif'

export const page29 = (pageData) => {
        let state = pageData.state
        const { t, i18n, classes } = pageData.props;

        let back_btn = t('common_btn.back')
        let buy_btn = t('common_btn.buy')


        let label_page_title = t('question.page_29.page_title')
        return(
            <>


                <img style={{width:"300px"}} src={thank_you_image}></img>
                <p className="question-title">{label_page_title}</p> 

            
                <Row className="question_btn">
                    <div style={{width:"100%", position:"relative", height:"50px"}}>
                    <Button style={{maxWidth:"300px", color:"white", position:"absolute", left: "50%",webkitTransform: "translateX(-50%)",transform: "translateX(-50%)"}} variant="outline-primary" size="lg" block className="next_btn" onClick={()=>pageData.nextPageWithoutValidation('page29','page30')} >{buy_btn}</Button>
                    </div>
                </Row>
                <Row className="question_btn">
                    <div style={{width:"100%", position:"relative", height:"50px"}}>
                    <Button style={{maxWidth:"300px", position:"absolute", position:"absolute", left: "50%",webkitTransform: "translateX(-50%)",transform: "translateX(-50%)"}} variant="outline-primary" size="lg" block className="back_btn" onClick={()=>pageData.prevPage('page28','page29')} >{back_btn}</Button>
                    </div>
                </Row>
            </>
        )
    }