import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Select from 'react-select';

export const page3 = (pageData) => {
        let state = pageData.state
        const { t, i18n, classes } = pageData.props;

        let back_btn = t('common_btn.back')
        let next_btn = t('common_btn.next')
        let label_page_title = t('question.page_3.page_title')
        let label_gender = t('question.page_3.gender')
        let label_marital_status = t('question.page_3.marital_status')
        let error_msg_detail = t('error_msg.error_msg_detail')
        let gender_options = t('gender', {returnObjects:true})

        let maritalStatus_options = t('maritalStatus', {returnObjects:true})

        


        console.log("maritalStatus_options ==> " + maritalStatus_options)

        // if(gender_options && !gender_options.label)gender_options = JSON.parse(gender_options)

        console.log("gender_options ==> " + gender_options)


        console.log("state.page3.maritalStatus ==> " + state.page3.maritalStatus)
        if(state.page3.maritalStatus && !state.page3.maritalStatus.label)state.page3.maritalStatus = JSON.parse(state.page3.maritalStatus)
        if(state.page3.gender && !state.page3.gender.label)state.page3.gender = JSON.parse(state.page3.gender)


        return(
            <>
               <p className="question-title">{label_page_title}</p>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>{label_gender}</Form.Label>
                        <Select style={{height:"1000px"}}
                            value={state.page3.gender}
                            onChange={pageData.handleChangeSelect('page3','gender')}
                            options={gender_options}
                        />
                        {state.errors.includes('error_page3_gender') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_marital_status}</Form.Label>
                        <Select
                            value={state.page3.maritalStatus}
                            onChange={pageData.handleChangeSelect('page3','maritalStatus')}
                            options={maritalStatus_options}
                        />
                        {state.errors.includes('error_page3_maritalStatus') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                        </Form.Group>
                    </Form.Row>
                    <Row className="question_btn">
                        <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={()=>pageData.prevPage('page2','page3')} >{back_btn}</Button></Col>
                        <Col sm></Col>
                        <Col sm></Col>
                        <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={()=>pageData.nextPage('page3','page4')} >{next_btn}</Button></Col>
                    </Row>
                    </Form>
            </>
        )
    }