import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from "react-datepicker";

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

export const referencePage4 = (pageData) => {
    let state = pageData.state
    const { t, i18n, classes } = pageData.props;
    let currentDate = new Date()

    let back_btn = t('common_btn.back')
    let next_btn = t('common_btn.next')
    let label_page_title = t('question.refrence.page_4.page_title')
    let label_name_of_organization = t('question.refrence.page_4.name_of_organization')
    let label_family_name = t('question.refrence.page_4.family_name')
    let label_first_name = t('question.refrence.page_4.first_name')
    let label_gender = t('question.refrence.page_4.gender')
    let label_date_of_birth = t('question.refrence.page_4.date_of_birth')
    let label_place_of_birth = t('question.refrence.page_4.place_of_birth')
    let label_nationality = t('question.refrence.page_4.nationality')
    let label_road = t('question.refrence.page_4.road')
    let label_house_number = t('question.refrence.page_4.house_number')
    let label_post_code = t('question.refrence.page_4.post_code')
    let label_location = t('question.refrence.page_4.location')
    let label_country = t('question.refrence.page_4.country')
    let label_phone = t('question.refrence.page_4.phone')
    let label_email = t('question.refrence.page_4.email')
    let error_msg_detail = t('error_msg.error_msg_detail')

    let genderOptions = t('gender', {returnObjects:true})


    if(state.referencePage4.dateOfBirth)state.referencePage4.dateOfBirth = new Date(state.referencePage4.dateOfBirth)
    if(state.referencePage4.gender && !state.referencePage4.gender.label)state.referencePage4.gender = JSON.parse(state.referencePage4.gender)
    if(state.referencePage4.nationality && !state.referencePage4.nationality.label)state.referencePage4.nationality = JSON.parse(state.referencePage4.nationality)
    if(state.referencePage4.country && !state.referencePage4.country.label)state.referencePage4.country = JSON.parse(state.referencePage4.country)



    return (
        <>
            <p className="question-title">{label_page_title}</p>
            <Form>
            <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_name_of_organization}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage4', 'organizationName')}
                            value={state.referencePage4.organizationName}
                        />
                        {state.errors.includes('error_referencePage4_name_of_organization') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>

                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_family_name}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage4', 'familyName')}
                            value={state.referencePage4.familyName}
                        />
                        {state.errors.includes('error_referencePage4_familyName') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_first_name}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage4', 'firstName')}
                            value={state.referencePage4.firstName}
                        />
                        {state.errors.includes('error_referencePage4_firstName') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_gender}</Form.Label>
                        <Select
                            onChange={pageData.handleChangeSelect('referencePage4', 'gender')}
                            value={state.referencePage4.gender}
                            options={genderOptions}
                        />
                        {state.errors.includes('error_referencePage4_gender') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Label>{label_date_of_birth}</Form.Label>
                            <DatePicker
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                maxDate={currentDate}
                                selected={state.referencePage4.dateOfBirth}
                                onSelect={pageData.handleChangeCalendar('referencePage4', 'dateOfBirth')} 
                                onChange={pageData.handleChangeCalendar('referencePage4', 'dateOfBirth')}
                            />
                            {state.errors.includes('error_page24_dateOfBirth') &&
                                <p className="error_class">{error_msg_detail}</p>
                            }
                    </Form.Group>
                    {/* <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_place_of_birth}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage4', 'placeOfBirth')}
                            value={state.referencePage4.placeOfBirth}
                        />
                        {state.errors.includes('error_referencePage4_placeOfBirth') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group> */}
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_nationality}</Form.Label>
                        <Select
                            onChange={pageData.handleChangeSelect('referencePage4', 'nationality')}
                            value={state.referencePage4.nationality}
                            options={state.countryListOptions}
                        />
                        {state.errors.includes('error_referencePage4_nationality') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_road}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage4', 'road')}
                            value={state.referencePage4.road}
                        />
                        {state.errors.includes('error_referencePage4_road') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_house_number}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage4', 'houseNumber')}
                            value={state.referencePage4.houseNumber}
                        />
                        {state.errors.includes('error_referencePage4_houseNumber') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_post_code}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage4', 'postCode')}
                            value={state.referencePage4.postCode}
                        />
                        {state.errors.includes('error_referencePage4_postCode') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_location}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage4', 'location')}
                            value={state.referencePage4.location}
                        />
                        {state.errors.includes('error_referencePage4_location') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    {/* <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_country}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage4', 'country')}
                            value={state.referencePage4.country}
                        />
                        {state.errors.includes('error_referencePage4_country') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group> */}
                    {/* <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_country}</Form.Label>
                        <Select
                            onChange={pageData.handleChangeSelect('referencePage4', 'nationality')}
                            value={state.referencePage4.nationality}
                            options={state.countryListOptions}
                        />
                        {state.errors.includes('error_referencePage4_nationality') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group> */}
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_country}</Form.Label>
                        <Select
                            onChange={pageData.handleChangeSelect('referencePage4', 'country')}
                            value={state.referencePage4.country}
                            options={state.countryListOptions}
                        />
                        {state.errors.includes('error_referencePage4_organizationCountry') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_phone}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage4', 'phone')}
                            value={state.referencePage4.phone}
                        />
                        {state.errors.includes('error_referencePage4_phone') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_email}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('referencePage4', 'email')}
                            value={state.referencePage4.email}
                        />
                        {state.errors.includes('error_referencePage4_email') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
             
                <Row className="question_btn">
                    <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={() => pageData.prevPage('page25', 'referencePage4')} >{back_btn}</Button></Col>
                    <Col sm></Col>
                    <Col sm></Col>
                    <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={() => pageData.nextPage('referencePage4', 'page26')} >{next_btn}</Button></Col>
                </Row>
            </Form>
        </>
    )
}