import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export const page12 = (pageData) => {
        let state = pageData.state
        const { t, i18n, classes } = pageData.props;

        let back_btn = t('common_btn.back')
        let next_btn = t('common_btn.next')
        let error_msg_detail = t('error_msg.error_msg_detail')
        let label_page_title = t('question.page_12.page_title')
        let label_type_of_travel_document = t('question.page_12.type_of_travel_document')
        let label_number_of_the_travel_document = t('question.page_12.number_of_the_travel_document')
        let label_national_identity_number = t('question.page_12.national_identity_number')

        let documentOptions = t('document', {returnObjects:true})


        if(state.page12.travelDocument && !state.page12.travelDocument.label)state.page12.travelDocument = JSON.parse(state.page12.travelDocument)

        return(
            <>
                <p className="question-title">{label_page_title}</p>
                <Form>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>{label_type_of_travel_document}</Form.Label>
                    <Select
                        value={state.page12.travelDocument}
                        onChange={pageData.handleChangeSelect('page12','travelDocument')}
                        options={documentOptions}
                    />
                    {state.errors.includes('error_page12_travelDocument') &&
                        <p className="error_class">{error_msg_detail}</p>
                    }
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Label>{label_number_of_the_travel_document}</Form.Label>
                    <Form.Control 
                        type="text" 
                        onChange={pageData.handleChange('page12','numberOfTravelDocument')} 
                        value={state.page12.numberOfTravelDocument} 
                    />
                    {state.errors.includes('error_page12_numberOfTravelDocument') &&
                        <p className="error_class">{error_msg_detail}</p>
                    }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>{label_national_identity_number}</Form.Label>
                    <Form.Control 
                        type="text" 
                        onChange={pageData.handleChange('page12','identityNumber')} 
                        value={state.page12.identityNumber} 
                    />
                    {state.errors.includes('error_page12_identityNumber') &&
                        <p className="error_class">{error_msg_detail}</p>
                    }
                    </Form.Group>
                </Form.Row>
                <Row className="question_btn">
                    <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={()=>pageData.prevPage('page11','page12')} >{back_btn}</Button></Col>
                    <Col sm></Col>
                    <Col sm></Col>
                    <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={()=>pageData.nextPage('page12','page13')} >{next_btn}</Button></Col>
                </Row>
            </Form>
        </>
        )
    }