import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export const page6 = (pageData) => {
        let state = pageData.state
        const { t, i18n, classes } = pageData.props;

        let back_btn = t('common_btn.back')
        let next_btn = t('common_btn.next')
        let error_msg_detail = t('error_msg.error_msg_detail')
        let label_page_title = t('question.page_6.page_title')
        let label_current_occupation = t('question.page_6.current_occupation')

        if(state.page6.currentOccupation && !state.page6.currentOccupation.label)state.page6.currentOccupation = JSON.parse(state.page6.currentOccupation)


        return(
            <>
                <p className="question-title">{label_page_title}</p>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>{label_current_occupation}</Form.Label>
                        <Select
                            value={state.page6.currentOccupation}
                            onChange={pageData.handleChangeSelect('page6','currentOccupation')}
                            options={state.occupationOptions}
                        />
                        {state.errors.includes('error_page6_currentOccupation') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                        </Form.Group>
                    </Form.Row>
                    <Row className="question_btn">
                        <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={()=>pageData.prevPage('page5','page6')} >{back_btn}</Button></Col>
                        <Col sm></Col>
                        <Col sm></Col>
                        <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={()=>pageData.nextPage('page6','page7')} >{next_btn}</Button></Col>
                    </Row>
                </Form>
            </>
        )
    }