import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";

export const page1 = (pageData) => {
        let state = pageData.state
        const { t, i18n, classes } = pageData.props;

        let back_btn = t('common_btn.back')
        let next_btn = t('common_btn.next')
        let error_msg_detail = t('error_msg.error_msg_detail')

        let label_page_title = t('question.page_1.page_title')
        let label_family_name = t('question.page_1.family_name')
        let label_birth_name = t('question.page_1.birth_name')
        let label_firstname = t('question.page_1.firstname')

        return(
            <>
                <p className="question-title">{label_page_title}</p>
                <Form>
                    <Form.Row>

                        <Form.Group as={Col} controlId="formGridAddress1">
                            <Form.Label>{label_firstname}</Form.Label>
                            <Form.Control type="text" onChange={pageData.handleChange('page1','firstName')} value={state.page1.firstName} />
                            {state.errors.includes('error_page1_firstname') &&
                                <p className="error_class">{error_msg_detail}</p>
                            }
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>{label_family_name}</Form.Label>
                        <Form.Control type="text" onChange={pageData.handleChange('page1','familyName')} value={state.page1.familyName} />
                        {state.errors.includes('error_page1_familyName') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                        </Form.Group>
                       
                    </Form.Row>

                    <Form.Group  controlId="formGridPassword">
                        <Form.Label>{label_birth_name}</Form.Label>
                        <Form.Control type="text" onChange={pageData.handleChange('page1','birthName')} value={state.page1.birthName} />
                        {state.errors.includes('error_page1_birthName') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                        </Form.Group>



                    <Row className="question_btn">
                        <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={()=>pageData.prevPage('page0','page1')} >{back_btn}</Button></Col>
                        <Col sm></Col>
                        <Col sm></Col>
                        <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={()=>pageData.nextPage('page1','page2')} >{next_btn}</Button></Col>
                    </Row>
                    </Form>
            </>
        )
    }