const staticOption = { 
    gender : [
        { label : "Male", value: "1"},
        { label : "Female", value: "2"},
        { label : "Unspecified", value: "3"},
    ],
    maritalStatus : [
        { label : "Unknown", value: "1"},
        { label : "Single", value: "2"},
        { label : "Married", value: "3"},
        { label : "Separated", value: "4"},
        { label : "Divorced", value: "5"},
        { label : "Widowed", value: "6"},
        { label : "Civil partnership", value: "7"}
    ],
    occupation : [
        { label : "Administrative, technical and service staff (diplomatic and consular posts) ", value: "1"},
        { label : "Architect", value: "2"},
        { label : "Artisan", value: "3"},
        { label : "Artist", value: "4"},
        { label : "Banker", value: "5"},
        { label : "Blue-collar worker", value: "6"},
        { label : "Businessperson", value: "7"},
        { label : "Chauffeur, lorry driver", value: "8"},
        { label : "Chemist/chemical engineer ", value: "9"},
        { label : "Civil servant ", value: "10"},
        { label : "Cleric, religious figure", value: "11"},
        { label : "Company executive", value: "12"},
        { label : "Computer expert", value: "13"},
        { label : "Diplomat ", value: "14"},
        { label : "Diplomat's domestic staff", value: "15"},
        { label : "Electronics expert ", value: "16"},
        { label : "Farmer", value: "17"},
        { label : "Fashion, cosmetics", value: "18"},
        { label : "Journalist", value: "19"},
        { label : "Judge, public prosecutor", value: "20"},
        { label : "Legal profession (lawyer, legal adviser)", value: "21"},
        { label : "Manager", value: "22"},
        { label : "Medical and paramedical profession (doctor, surgeon, nurse, vet)", value: "23"},
        { label : "Pensioner", value: "24"},
        { label : "Police officer, military personnel", value: "25"},
        { label : "Politician", value: "26"},
        { label : "Professional sportsperson, trainer", value: "27"},
        { label : "Researcher", value: "28"},
        { label : "Seaman", value: "29"},
        { label : "Self-employed", value: "30"},
        { label : "Student, trainee", value: "31"},
        { label : "Teacher", value: "31"},
        { label : "Technician with other specialist field", value: "32"},
        { label : "White-collar worker", value: "33"},
        { label : "No occupation", value: "34"},
        { label : "Other professions", value: "35"},
    ],
    document : [
        { label : "Ordinary passport", value: "1"},
        { label : "Group passport", value: "2"},
        { label : "Protection passport", value: "3"},
        { label : "Diplomatic passport", value: "4"},
        { label : "Service passport", value: "5"},
        { label : "Official duty passport", value: "6"},
        { label : "Special passport", value: "7"},
        { label : "Passport for aliens", value: "8"},
        { label : "National laissez-passer", value: "9"},
        { label : "United Nations laissez-passer", value: "10"},
        { label : "Travel document for refugees (1951 Geneva Convention)", value: "11"},
        { label : "Travel document for stateless persons (1954 New York Convention)", value: "12"},
        { label : "Seaman's book", value: "13"},
        { label : "Others", value: "14"}
    ],
    numberOfEntriesRequested : [
        { label : "Multiple entries", value: "1"},
        { label : "Single entry", value: "1"},
        { label : "Two entries", value: "1"}
    ],
    typeOfEntryPermit : [
        { label : "Other", value: "1"},
        { label : "re-entry visa", value: "2"},
        { label : "residence permit", value: "3"},
        { label : "resident alien card", value: "4"}
    ],
    mainPurposeOfJourney : [
        { label : "Airport transit", value: "1"},
        { label : "Business", value: "2"},
        { label : "Cultural", value: "3"},
        { label : "Medical Reasons", value: "4"},
        { label : "Official Visit", value: "5"},
        { label : "Others", value: "6"},
        { label : "Sport", value: "7"},
        { label : "Study", value: "8"},
        { label : "Tourism", value: "9"},
        { label : "Transit", value: "10"},
        { label : "Visit of family or friends", value: "11"}
    ],


    organization_objectives : [
        { label : "Agriculture", value: "1"},
        { label : "Banking", value: "2"},
        { label : "Culture/art", value: "3"},
        { label : "Education", value: "4"},
        { label : "Fashion/ cosmetics", value: "5"},
        { label : "Industry", value: "6"},
        { label : "Medicine", value: "7"},
        { label : "Military/police force", value: "8"},
        { label : "Non-profit-making association", value: "9"},
        { label : "Others", value: "10"},
        { label : "Press", value: "11"},
        { label : "Public administration/government", value: "11"},
        { label : "Religious association/church", value: "12"},
        { label : "Sport", value: "13"},
        { label : "Tourism", value: "14"},
        { label : "Transport", value: "15"}
    ],

    name_of_register_of_organization : [
        { label : "Commercial register", value: "1"},
        { label : "Other German or foreign registers", value: "2"},
        { label : "Register of associations", value: "3"},
        { label : "Register of cooperatives", value: "4"},
        { label : "Register of crafts and trades", value: "5"},
        { label : "Register of partnerships", value: "6"}
    ],

    stateList : [
        { label : "Austria", value: "1"},
        { label : "Belgium", value: "2"},
        { label : "Czechia", value: "3"},
        { label : "Denmark", value: "4"},
        { label : "Estonia", value: "5"},
        { label : "Finland", value: "6"},
        { label : "France", value: "7"},
        { label : "Germany", value: "8"},
        { label : "Greece", value: "9"},
        { label : "Hungary", value: "10"},
        { label : "Iceland", value: "11"},
        { label : "Italy", value: "12"},
        { label : "Latvia", value: "13"},
        { label : "Liechtenstein", value: "14"},
        { label : "Lithuania", value: "15"},
        { label : "Luxembourg", value: "16"},
        { label : "Malta", value: "17"},
        { label : "Netherlands", value: "18"},
        { label : "Norway", value: "19"},
        { label : "Poland", value: "20"},
        { label : "Portugal", value: "21"},
        { label : "Slovakia", value: "22"},
        { label : "Slovenia", value: "23"},
        { label : "Spain", value: "24"},
        { label : "Sweden", value: "25"},
        { label : "Switzerland", value: "26"}
    ],
    countryList : [
                { label : "Afghanistan", value: "AF"},
                { label : "Åland Islands", value: "AX"},
                { label : "Albania", value: "AL"},
                { label : "Algeria", value: "DZ"},
                { label : "American Samoa", value: "AS"},
                { label : "Andorra", value: "AD"},
                { label : "Angola", value: "AO"},
                { label : "Anguilla", value: "AI"},
                { label : "Antarctica", value: "AQ"},
                { label : "Antigua and Barbuda", value: "AG"},
                { label : "Argentina", value: "AR"},
                { label : "Armenia", value: "AM"},
                { label : "Aruba", value: "AW"},
                { label : "Australia", value: "AU"},
                { label : "Austria", value: "AT"},
                { label : "Azerbaijan", value: "AZ"},
                { label : "Bahamas", value: "BS"},
                { label : "Bahrain", value: "BH"},
                { label : "Bangladesh", value: "BD"},
                { label : "Barbados", value: "BB"},
                { label : "Belarus", value: "BY"},
                { label : "Belgium", value: "BE"},
                { label : "Belize", value: "BZ"},
                { label : "Benin", value: "BJ"},
                { label : "Bermuda", value: "BM"},
                { label : "Bhutan", value: "BT"},
                { label : "Bolivia", value: "BO"},
                { label : "Bosnia and Herzegovina", value: "BA"},
                { label : "Botswana", value: "BW"},
                { label : "Bouvet Island", value: "BV"},
                { label : "Brazil", value: "BR"},
                { label : "British Indian Ocean Territory", value: "IO"},
                { label : "Brunei Darussalam", value: "BN"},
                { label : "Bulgaria", value: "BG"},
                { label : "Burkina Faso", value: "BF"},
                { label : "Burundi", value: "BI"},
                { label : "Cambodia", value: "KH"},
                { label : "Cameroon", value: "CM"},
                { label : "Canada", value: "CA"},
                { label : "Cape Verde", value: "CV"},
                { label : "Cayman Islands", value: "KY"},
                { label : "Central African Republic", value: "CF"},
                { label : "Chad", value: "TD"},
                { label : "Chile", value: "CL"},
                { label : "China", value: "CN"},
                { label : "Christmas Island", value: "CX"},
                { label : "Cocos (Keeling) Islands", value: "CC"},
                { label : "Colombia", value: "CO"},
                { label : "Comoros", value: "KM"},
                { label : "Congo", value: "CG"},
                { label : "Congo, The Democratic Republic of the", value: "CD"},
                { label : "Cook Islands", value: "CK"},
                { label : "Costa Rica", value: "CR"},
                { label : "Cote D'Ivoire", value: "CI"},
                { label : "Croatia", value: "HR"},
                { label : "Cuba", value: "CU"},
                { label : "Cyprus", value: "CY"},
                { label : "Czech Republic", value: "CZ"},
                { label : "Denmark", value: "DK"},
                { label : "Djibouti", value: "DJ"},
                { label : "Dominica", value: "DM"},
                { label : "Dominican Republic", value: "DO"},
                { label : "Ecuador", value: "EC"},
                { label : "Egypt", value: "EG"},
                { label : "El Salvador", value: "SV"},
                { label : "Equatorial Guinea", value: "GQ"},
                { label : "Eritrea", value: "ER"},
                { label : "Estonia", value: "EE"},
                { label : "Ethiopia", value: "ET"},
                { label : "Falkland Islands (Malvinas)", value: "FK"},
                { label : "Faroe Islands", value: "FO"},
                { label : "Fiji", value: "FJ"},
                { label : "Finland", value: "FI"},
                { label : "France", value: "FR"},
                { label : "French Guiana", value: "GF"},
                { label : "French Polynesia", value: "PF"},
                { label : "French Southern Territories", value: "TF"},
                { label : "Gabon", value: "GA"},
                { label : "Gambia", value: "GM"},
                { label : "Georgia", value: "GE"},
                { label : "Germany", value: "DE"},
                { label : "Ghana", value: "GH"},
                { label : "Gibraltar", value: "GI"},
                { label : "Greece", value: "GR"},
                { label : "Greenland", value: "GL"},
                { label : "Grenada", value: "GD"},
                { label : "Guadeloupe", value: "GP"},
                { label : "Guam", value: "GU"},
                { label : "Guatemala", value: "GT"},
                { label : "Guernsey", value: "GG"},
                { label : "Guinea", value: "GN"},
                { label : "Guinea-Bissau", value: "GW"},
                { label : "Guyana", value: "GY"},
                { label : "Haiti", value: "HT"},
                { label : "Heard Island and Mcdonald Islands", value: "HM"},
                { label : "Holy See (Vatican City State)", value: "VA"},
                { label : "Honduras", value: "HN"},
                { label : "Hong Kong", value: "HK"},
                { label : "Hungary", value: "HU"},
                { label : "Iceland", value: "IS"},
                { label : "India", value: "IN"},
                { label : "Indonesia", value: "ID"},
                { label : "Iran, Islamic Republic Of", value: "IR"},
                { label : "Iraq", value: "IQ"},
                { label : "Ireland", value: "IE"},
                { label : "Isle of Man", value: "IM"},
                { label : "Israel", value: "IL"},
                { label : "Italy", value: "IT"},
                { label : "Jamaica", value: "JM"},
                { label : "Japan", value: "JP"},
                { label : "Jersey", value: "JE"},
                { label : "Jordan", value: "JO"},
                { label : "Kazakhstan", value: "KZ"},
                { label : "Kenya", value: "KE"},
                { label : "Kiribati", value: "KI"},
                { label : "Korea, Democratic PeopleS Republic of", value: "KP"},
                { label : "Korea, Republic of", value: "KR"},
                { label : "Kuwait", value: "KW"},
                { label : "Kyrgyzstan", value: "KG"},
                { label : "Lao People'S Democratic Republic", value: "LA"},
                { label : "Latvia", value: "LV"},
                { label : "Lebanon", value: "LB"},
                { label : "Lesotho", value: "LS"},
                { label : "Liberia", value: "LR"},
                { label : "Libyan Arab Jamahiriya", value: "LY"},
                { label : "Liechtenstein", value: "LI"},
                { label : "Lithuania", value: "LT"},
                { label : "Luxembourg", value: "LU"},
                { label : "Macao", value: "MO"},
                { label : "Macedonia, The Former Yugoslav Republic of", value: "MK"},
                { label : "Madagascar", value: "MG"},
                { label : "Malawi", value: "MW"},
                { label : "Malaysia", value: "MY"},
                { label : "Maldives", value: "MV"},
                { label : "Mali", value: "ML"},
                { label : "Malta", value: "MT"},
                { label : "Marshall Islands", value: "MH"},
                { label : "Martinique", value: "MQ"},
                { label : "Mauritania", value: "MR"},
                { label : "Mauritius", value: "MU"},
                { label : "Mayotte", value: "YT"},
                { label : "Mexico", value: "MX"},
                { label : "Micronesia, Federated States of", value: "FM"},
                { label : "Moldova, Republic of", value: "MD"},
                { label : "Monaco", value: "MC"},
                { label : "Mongolia", value: "MN"},
                { label : "Montserrat", value: "MS"},
                { label : "Morocco", value: "MA"},
                { label : "Mozambique", value: "MZ"},
                { label : "Myanmar", value: "MM"},
                { label : "Namibia", value: "NA"},
                { label : "Nauru", value: "NR"},
                { label : "Nepal", value: "NP"},
                { label : "Netherlands", value: "NL"},
                { label : "Netherlands Antilles", value: "AN"},
                { label : "New Caledonia", value: "NC"},
                { label : "New Zealand", value: "NZ"},
                { label : "Nicaragua", value: "NI"},
                { label : "Niger", value: "NE"},
                { label : "Nigeria", value: "NG"},
                { label : "Niue", value: "NU"},
                { label : "Norfolk Island", value: "NF"},
                { label : "Northern Mariana Islands", value: "MP"},
                { label : "Norway", value: "NO"},
                { label : "Oman", value: "OM"},
                { label : "Pakistan", value: "PK"},
                { label : "Palau", value: "PW"},
                { label : "Palestinian Territory, Occupied", value: "PS"},
                { label : "Panama", value: "PA"},
                { label : "Papua New Guinea", value: "PG"},
                { label : "Paraguay", value: "PY"},
                { label : "Peru", value: "PE"},
                { label : "Philippines", value: "PH"},
                { label : "Pitcairn", value: "PN"},
                { label : "Poland", value: "PL"},
                { label : "Portugal", value: "PT"},
                { label : "Puerto Rico", value: "PR"},
                { label : "Qatar", value: "QA"},
                { label : "Reunion", value: "RE"},
                { label : "Romania", value: "RO"},
                { label : "Russian Federation", value: "RU"},
                { label : "RWANDA", value: "RW"},
                { label : "Saint Helena", value: "SH"},
                { label : "Saint Kitts and Nevis", value: "KN"},
                { label : "Saint Lucia", value: "LC"},
                { label : "Saint Pierre and Miquelon", value: "PM"},
                { label : "Saint Vincent and the Grenadines", value: "VC"},
                { label : "Samoa", value: "WS"},
                { label : "San Marino", value: "SM"},
                { label : "Sao Tome and Principe", value: "ST"},
                { label : "Saudi Arabia", value: "SA"},
                { label : "Senegal", value: "SN"},
                { label : "Serbia and Montenegro", value: "CS"},
                { label : "Seychelles", value: "SC"},
                { label : "Sierra Leone", value: "SL"},
                { label : "Singapore", value: "SG"},
                { label : "Slovakia", value: "SK"},
                { label : "Slovenia", value: "SI"},
                { label : "Solomon Islands", value: "SB"},
                { label : "Somalia", value: "SO"},
                { label : "South Africa", value: "ZA"},
                { label : "South Georgia and the South Sandwich Islands", value: "GS"},
                { label : "Spain", value: "ES"},
                { label : "Sri Lanka", value: "LK"},
                { label : "Sudan", value: "SD"},
                { label : "Sur label" , value: "SR"},
                { label : "Svalbard and Jan Mayen", value: "SJ"},
                { label : "Swaziland", value: "SZ"},
                { label : "Sweden", value: "SE"},
                { label : "Switzerland", value: "CH"},
                { label : "Syrian Arab Republic", value: "SY"},
                { label : "Taiwan, Province of China", value: "TW"},
                { label : "Tajikistan", value: "TJ"},
                { label : "Tanzania, United Republic of", value: "TZ"},
                { label : "Thailand", value: "TH"},
                { label : "Timor-Leste", value: "TL"},
                { label : "Togo", value: "TG"},
                { label : "Tokelau", value: "TK"},
                { label : "Tonga", value: "TO"},
                { label : "Trinidad and Tobago", value: "TT"},
                { label : "Tunisia", value: "TN"},
                { label : "Turkey", value: "TR"},
                { label : "Turkmenistan", value: "TM"},
                { label : "Turks and Caicos Islands", value: "TC"},
                { label : "Tuvalu", value: "TV"},
                { label : "Uganda", value: "UG"},
                { label : "Ukraine", value: "UA"},
                { label : "United Arab Emirates", value: "AE"},
                { label : "United Kingdom", value: "GB"},
                { label : "United States", value: "US"},
                { label : "United States Minor Outlying Islands", value: "UM"},
                { label : "Uruguay", value: "UY"},
                { label : "Uzbekistan", value: "UZ"},
                { label : "Vanuatu", value: "VU"},
                { label : "Venezuela", value: "VE"},
                { label : "Viet Nam", value: "VN"},
                { label : "Virgin Islands, British", value: "VG"},
                { label : "Virgin Islands, U.S.", value: "VI"},
                { label : "Wallis and Futuna", value: "WF"},
                { label : "Western Sahara", value: "EH"},
                { label : "Yemen", value: "YE"},
                { label : "Zambia", value: "ZM"},
                { label : "Zimbabwe", value: "ZW"}
            ]
}


module.exports  = staticOption