import * as actionTypes from '../actionTypes';
import { questionService } from '../../../services/index'

const questionStart = () => {
    return {
        type: actionTypes.QUESTION_START
    };
};

const questionSuccess = (question, id_of_answer) => {
    return {
        type: actionTypes.QUESTION_SUCCESS,
        question,
        id_of_answer
    };
};

const verifySuccess = (allowed_state) => {
    return {
        type: actionTypes.VERIFY_SUCCESS,
        allowed_state
    };
};

const questionFail = (error_code) => {
    return {
        type: actionTypes.QUESTION_FAIL,
        error_code
    };
};

//Please pass the id of the current question and the id of the answer which the user selected (not the next_question_id)
export function postQuestionForm(object) {
    return dispatch => {
        dispatch(questionStart());

        questionService.postQuestionForm(object)
            .then(
                data => {

                    //dispatch(questionSuccess(data.question, data.id_of_answer));

                    //history.push('/');
                },
                error => {
                    dispatch(questionFail(error));
                }
            );
    };
}

//No parameters necessary
export function getPreviousQuestion() {
    return dispatch => {
        dispatch(questionStart());

        questionService.getPreviousQuestion()
            .then(
                data => {

                    dispatch(questionSuccess(data.question, data.id_of_answer));

                    //history.push('/');
                },
                error => {
                    dispatch(questionFail(error));
                }
            );
    };
}
//You can ignore the save Function, because it now saves automatically when you say next question
export function saveQuestion(id_of_question, id_of_answer) {
    return dispatch => {
        dispatch(questionStart());

        questionService.saveQuestion(id_of_question, id_of_answer)
            .then(
                data => {

                },
                error => {
                    dispatch(questionFail(error));
                }
            );
    };
}

//No parameters necessary
export function verifyAllQuestions(id_of_question, id_of_answer) {
    return dispatch => {
        dispatch(questionStart());

        questionService.verifyAllQuestions(id_of_question, id_of_answer)
            .then(
                data => {

                    dispatch(verifySuccess(data.allowed_state))

                    //history.push('/');
                },
                error => {
                    dispatch(questionFail(error));
                }
            );
    };
}
