import React, { useState }  from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
    Link,
} from 'react-router-dom';
import PrivateRoute from './PrivateRoute'
import Auth from '../containers/Auth/Auth'
import Signin from '../containers/Signin'
import Register from '../containers/Register'
import ResetPassword from '../containers/ResetPassword'
import NewPassword from '../containers/NewPassword'
import ConfirmationPassword from '../containers/ConfirmationPassword'
import CheckEmail from '../containers/CheckEmail'
import QuestionBeta from '../containers/QuestionBeta'
import Result from '../containers/Result'
import SelectCountry from '../containers/SelectCountry'
import ValidateUser from '../containers/ValidateUser'



function AllRoutes(props) {

    return (
        <Switch >
            <PrivateRoute exact path="/page0" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page1" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page2" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page3" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page4" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page5" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page6" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page7" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page8" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page9" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page10" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page11" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page12" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page13" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page14" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page15" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page16" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page17" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page18" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page19" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page20" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page21" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page22" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page23" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page24" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page25" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page26" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page27" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page28" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page29" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page30" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page31" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/page32" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/referencepage1" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/referencepage2" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/referencepage3" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/referencepage4" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/obligorpage1" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/obligorpage2" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />
            <PrivateRoute exact path="/obligorpage3" component={() => <QuestionBeta form={props.form} postData={(page, object) => props.postData(page, object)}></QuestionBeta>} />




            <Route path="/validate-user" component={() => <ValidateUser validateUser={(id) => props.validateUser(id)}></ValidateUser>} />

            


            <Route path="/select" component={() => <SelectCountry></SelectCountry>} />

            <Route path="/de/signin" component={() => <Signin lang={"DE"}></Signin>} />
            <Route path="/de/register" component={() => <Register lang={"DE"}></Register>} />
            <Route path="/de/resetpassword" component={() => <ResetPassword lang={"DE"}></ResetPassword>} />
            <Route path="/de/newpassword" component={() => <NewPassword lang={"DE"}></NewPassword>} />
            <Route path="/de/confirmationpassword" component={() => <ConfirmationPassword></ConfirmationPassword>} />
            <Route path="/de/checkemail" component={() => <CheckEmail lang={"DE"}></CheckEmail>} />


            <Route path="/en/signin" component={() => <Signin lang={"EN"} ></Signin>} />
            <Route path="/en/register" component={() => <Register lang={"EN"} ></Register>} />
            <Route path="/en/resetpassword" component={() => <ResetPassword lang={"EN"} ></ResetPassword>} />
            <Route path="/en/newpassword" component={() => <NewPassword lang={"EN"} ></NewPassword>} />
            <Route path="/en/confirmationpassword" component={() => <ConfirmationPassword lang={"EN"} ></ConfirmationPassword>} />
            <Route path="/en/checkemail" component={() => <CheckEmail lang={"EN"} ></CheckEmail>} />

            <Redirect from='*' to='/en/signin' />
        </Switch>
    );
};

export default AllRoutes;