import auth_reducer from './authentication/authentication'
import question_reducer from './question/question'

import { combineReducers } from 'redux';


const rootReducer = combineReducers({
  auth: auth_reducer,
  question: question_reducer
});

export default rootReducer;
