import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export const page8 = (pageData) => {
        let state = pageData.state
        const { t, i18n, classes } = pageData.props;
        
        let back_btn = t('common_btn.back')
        let next_btn = t('common_btn.next')
        let error_msg_detail = t('error_msg.error_msg_detail')
        let label_page_title = t('question.page_8.page_title')
        let label_post = t('question.page_8.postal_code')
        let label_house_number = t('question.page_8.place')
        let label_land = t('question.page_8.land')


        console.log("state.page8.land", state.page8.land)
        if(state.page8.land && !state.page8.land.label)state.page8.land = JSON.parse(state.page8.land)

        return(
            <>
            <p className="question-title">{label_page_title}</p>
            <Form>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>{label_post}</Form.Label>
                        <Form.Control 
                            type="text" 
                            onChange={pageData.handleChange('page8','postal_code')} 
                            value={state.page8.postal_code} 
                        />
                        {state.errors.includes('error_page8_post') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_house_number}</Form.Label>
                        <Form.Control 
                            type="text" 
                            onChange={pageData.handleChange('page8','place')} 
                            value={state.page8.place} 
                        />
                        {state.errors.includes('error_page8_place') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>

                <Form.Group controlId="formGridAddress1">
                    <Form.Label>{label_land}</Form.Label>
                    <Select
                        value={state.page8.land}
                        onChange={pageData.handleChangeSelect('page8','land')}
                        options={state.countryListOptions}
                    />
                    {state.errors.includes('error_page8_land') &&
                        <p className="error_class">{error_msg_detail}</p>
                    }
                </Form.Group>
                <Row className="question_btn">
                    <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={()=>pageData.prevPage('page7','page8')} >{back_btn}</Button></Col>
                    <Col sm></Col>
                    <Col sm></Col>
                    <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={()=>pageData.nextPage('page8','page9')} >{next_btn}</Button></Col>
                </Row>
            </Form>
        </>
        )
    }