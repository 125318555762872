import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export const page17 = (pageData) => {
        let state = pageData.state
        const { t, i18n, classes } = pageData.props;

        let back_btn = t('common_btn.back')
        let next_btn = t('common_btn.next')
        let error_msg_detail = t('error_msg.error_msg_detail')
        let label_page_title = t('question.page_17.page_title')
        let label_main_issue_of_the_trip = t('question.page_17.main_issue_of_the_trip')
        let label_other_please_specify = t('question.page_17.other_please_specify')
        let label_member_state_of_first_entry = t('question.page_17.member_state_of_first_entry')

        let mainPurposeOfJourney_options = t('mainPurposeOfJourney', {returnObjects:true})


        if(state.page17.mainIssue && !state.page17.mainIssue.label)state.page17.mainIssue = JSON.parse(state.page17.mainIssue)
        if(state.page17.memberState && !state.page17.memberState.label)state.page17.memberState = JSON.parse(state.page17.memberState)


        return(
            <>
                <p className="question-title">{label_page_title}</p>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>{label_main_issue_of_the_trip}</Form.Label>
                            <Select
                                 value={state.page17.mainIssue} 
                                onChange={pageData.handleChangeSelect('page17','mainIssue')}
                                options={mainPurposeOfJourney_options}
                            />
                            {state.errors.includes('error_page17_mainIssue') &&
                                <p className="error_class">{error_msg_detail}</p>
                            }
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>{label_other_please_specify}</Form.Label>
                            <Form.Control 
                                type="text" 
                                onChange={pageData.handleChange('page17','other')} 
                                value={state.page17.other} 
                            />
                            {state.errors.includes('error_page17_other') &&
                                <p className="error_class">{error_msg_detail}</p>
                            }
                        </Form.Group>
                    </Form.Row>

                    <Form.Group controlId="formGridAddress1">
                        <Form.Label>{label_member_state_of_first_entry}</Form.Label>
                        <Select
                            value={state.page17.memberState} 
                            onChange={pageData.handleChangeSelect('page17','memberState')}
                            options={state.stateListOptions}
                        />
                        {state.errors.includes('error_page17_memberState') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Row className="question_btn">
                        <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={()=>pageData.prevPage('page16','page17')} >{back_btn}</Button></Col>
                        <Col sm></Col>
                        <Col sm></Col>
                        <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={()=>pageData.nextPage('page17','page18')} >{next_btn}</Button></Col>
                    </Row>
                </Form>
            </>
        )
    }