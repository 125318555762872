import React, { Component, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { LOCAL_STORAGE_TOKEN} from '../../shared/consts'

import order_homework_image from '../../assets/img/order_homework.png'
import order_documents_image from '../../assets/img/order_documents.png'

import {loadStripe} from '@stripe/stripe-js';


export  function Page30 (pageData) {
        let state = pageData.state
        const { t, i18n, classes } = pageData.props;

        let back_btn = t('common_btn.back')
        let buy_btn = t('common_btn.order')


        let label_page_title = t('question.page_30.page_title')

        const stripePromise = loadStripe('pk_test_ipvI1SXqskTlaGP0wmbKLJhK00r0M7Kydj');


        const products = [{price: "price_1Ilr4SAK3UQ6I85MXQk29NS4", quantity: 1, amount:19.99}, 
                        // {price: "price_1Ilr7MAK3UQ6I85MVrU7Bdv9", quantity: 1, amount:19.99},
                        // {price: "price_1Ilr6pAK3UQ6I85MLC8oGNhT", quantity: 1, amount:29.99}
                    ]





        const checkboxChangeHandler = (event) => {

            const array_number = Number(event.target.value)

            console.log("array_number", array_number)

            const product = products[array_number]

            if(event.target.checked){

                state.ordered_products.push(product)


                pageData.setTotalOrderAmount(state.total_order_amount += Number(product.amount))
            }else{
                let placeholder_value = state.total_order_amount - Number(product.amount)

                placeholder_value = Math.round(placeholder_value * 100) / 100
                pageData.setTotalOrderAmount(placeholder_value)


                for(let i = 0; i < state.ordered_products.length; i++){

                    if(state.ordered_products[i]){
                        if(state.ordered_products[i].price == product.price)state.ordered_products.splice(i, 1)
                    }
                }
            }

            console.log(JSON.stringify(state.ordered_products))

        }

        const checkoutButtonClickHandler = async () => {

            const stripe = await stripePromise;

            for(let i = 0; i < state.ordered_products.length; i++){

                delete state.ordered_products[i].amount
            }

            let token = localStorage.getItem(LOCAL_STORAGE_TOKEN)


            if(token)token = token.replace(/['"]+/g, '')
        
            const bearerToken = `Bearer ${token}` 


            const url = "https://abfrage-software.herokuapp.com/create-checkout-session"


            const response = await fetch(url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                // mode: 'no-cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: { 'Content-Type': 'application/json',
                'Authorization': bearerToken},

                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                // body: JSON.stringify(data) // body data type must match "Content-Type" header
              });


            const data = await response.json()

            console.log(data)

            const session_id = data.id


            stripe.redirectToCheckout({ sessionId: session_id });
 
        }


        return(
            <>


                <p className="question-title">{label_page_title}</p> 

            
                <div className="order_header_container">
                    <p className="order_header_left">Service name</p>
                    <p className="order_header_right">Price</p>
                </div>
                <div className="main_order_container">
                    <p className="main_order_title">Immigration Service</p>
                    <p className="order_price_text order_header_right">€ 19.99</p>
                </div>
                {/* <div className="additinoal_order_main_container">
                    <div className="additional_order_container">
                        <div>
                            <img className="additional_order_image"  src={order_documents_image}></img>
                        </div>
                        <div className="additional_order_text_container">
                            <p className="additional_order_text">Donec sclerisque</p>
                            <p className="order_price_text" >€ 19.99</p>
                        </div>
                        <div>
                            <input value="1" onChange={(event) => checkboxChangeHandler(event)} className="order_checkbox" type="checkbox"></input>
                        </div>
                    </div>
                    <span></span>
                    <div className="additional_order_container"> 
                        <div>
                            <img className="additional_order_image"  src={order_homework_image}></img>
                        </div>
                        <div className="additional_order_text_container">
                            <p className="additional_order_text">Maescanas iacinia volupat varius</p>
                            <p className="order_price_text">€ 29.99</p>
                        </div>
                        <div>
                            <input value="2" onChange={(event) => checkboxChangeHandler(event)} className="order_checkbox" type="checkbox"></input>
                        </div>
                    </div>
                </div> */}


                <div className="additinoal_order_main_container">
                    <span></span>
                    <span></span>
                    <div className="total_order_container"> 
                        <div>
                        <p className="main_order_title order_header_left">Total</p>
                        </div>
                        <div>
                        <p className="order_price_text order_header_right">€ {state.total_order_amount}</p>
                        </div>
                    </div>
                </div>


                <Row className="question_btn">
                        <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={()=>pageData.prevPage('page26','page27')} >{back_btn}</Button></Col>
                        <Col sm></Col>
                        <Col sm></Col>
                        <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={()=> checkoutButtonClickHandler()} >{buy_btn}</Button></Col>
                </Row>
            </>
        )
    }