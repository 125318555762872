import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from "react-datepicker";

export const obligorPage3 = (pageData) => {
    let state = pageData.state
    const { t, i18n, classes } = pageData.props;

    const currentDate = new Date()

    let back_btn = t('common_btn.back')
    let next_btn = t('common_btn.next')
    let label_page_title = t('question.Obligor.page_3.page_title')
    let label_name_insitution = t('question.Obligor.page_3.name_insitution')
    let label_surname_contact_person = t('question.Obligor.page_2.surname_contact_person')
    let label_first_name_contact_person = t('question.Obligor.page_2.first_name_contact_person')
    let label_gender = t('question.Obligor.page_3.gender')
    let label_date_of_birth = t('question.Obligor.page_3.date_of_birth')
    let label_place_of_birth = t('question.Obligor.page_3.place_of_birth')
    let label_nationality = t('question.Obligor.page_3.nationality')
    let label_road = t('question.Obligor.page_3.road')
    let label_house_number = t('question.Obligor.page_3.house_number')
    let label_post_code = t('question.Obligor.page_3.post_code')
    let label_location = t('question.Obligor.page_3.location')
    let label_country = t('question.Obligor.page_3.country')
    let label_phone = t('question.Obligor.page_3.phone')
    let label_email = t('question.Obligor.page_3.email')
    let error_msg_detail = t('error_msg.error_msg_detail')


    if(state.obligorPage3.dateOfBirth)state.obligorPage3.dateOfBirth = new Date(state.obligorPage3.dateOfBirth)
    if(state.obligorPage3.gender && !state.obligorPage3.gender.label)state.obligorPage3.gender = JSON.parse(state.obligorPage3.gender)
    if(state.obligorPage3.organizationTask && !state.obligorPage3.organizationTask.label)state.obligorPage3.organizationTask = JSON.parse(state.obligorPage3.organizationTask)
    if(state.obligorPage3.organiationRegisterName && !state.obligorPage3.organiationRegisterName.label)state.obligorPage3.organiationRegisterName = JSON.parse(state.obligorPage3.organiationRegisterName)
    if(state.obligorPage3.country && !state.obligorPage3.country.label)state.obligorPage3.country = JSON.parse(state.obligorPage3.country)
    if(state.obligorPage3.nationality && !state.obligorPage3.nationality.label)state.obligorPage3.nationality = JSON.parse(state.obligorPage3.nationality)




    return (
        <>
            <p className="question-title">{label_page_title}</p>
            <Form>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_name_insitution}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage3', 'insitutionName')}
                            value={state.obligorPage3.insitutionName}
                        />
                        {state.errors.includes('error_obligorPage3_insitutionName') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_surname_contact_person}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage3', 'contactPersonSurname')}
                            value={state.obligorPage3.contactPersonSurname}
                        />
                        {state.errors.includes('error_obligorPage3_contactPersonSurname') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_first_name_contact_person}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage3', 'contactPersonFirstname')}
                            value={state.obligorPage3.contactPersonFirstname}
                        />
                        {state.errors.includes('error_obligorPage3_contactPersonFirstname') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_gender}</Form.Label>
                        <Select
                            onChange={pageData.handleChangeSelect('obligorPage3', 'gender')}
                            value={state.obligorPage3.gender}
                            options={state.genderOptions}
                        />
                        {state.errors.includes('error_obligorPage3_gender') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>{label_date_of_birth}</Form.Label>
                            <DatePicker
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                maxDate={currentDate}
                                selected={state.obligorPage3.dateOfBirth}
                                onSelect={pageData.handleChangeCalendar('obligorPage3', 'dateOfBirth')} //when day is clicked
                                onChange={pageData.handleChangeCalendar('obligorPage3', 'dateOfBirth')} //only when value has changed
                            />
                              {state.errors.includes('error_obligorPage3_dateOfBirth') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                        </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_place_of_birth}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage3', 'placeOfBirth')}
                            value={state.obligorPage3.placeOfBirth}
                        />
                        {state.errors.includes('error_obligorPage3_placeOfBirth') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_nationality}</Form.Label>
                        <Select
                            onChange={pageData.handleChangeSelect('obligorPage3', 'nationality')}
                            value={state.obligorPage3.nationality}
                            options={state.countryListOptions}
                        />
                        {state.errors.includes('error_obligorPage3_nationality') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_road}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage3', 'road')}
                            value={state.obligorPage3.road}
                        />
                        {state.errors.includes('error_obligorPage3_road') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_house_number}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage3', 'houseNumber')}
                            value={state.obligorPage3.houseNumber}
                        />
                        {state.errors.includes('error_obligorPage3_houseNumber') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_post_code}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage3', 'postCode')}
                            value={state.obligorPage3.postCode}
                        />
                        {state.errors.includes('error_obligorPage3_postCode') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_location}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage3', 'location')}
                            value={state.obligorPage3.location}
                        />
                        {state.errors.includes('error_obligorPage3_location') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    {/* <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_country}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage3', 'country')}
                            value={state.obligorPage3.country}
                        />
                        {state.errors.includes('error_obligorPage3_country') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group> */}
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_country}</Form.Label>
                        <Select
                            onChange={pageData.handleChangeSelect('obligorPage3', 'country')}
                            value={state.obligorPage3.country}
                            options={state.countryListOptions}
                        />
                        {state.errors.includes('error_obligorPage3_organizationCountry') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_phone}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage3', 'phone')}
                            value={state.obligorPage3.phone}
                        />
                        {state.errors.includes('error_obligorPage3_phone') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_email}</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={pageData.handleChange('obligorPage3', 'email')}
                            value={state.obligorPage3.email}
                        />
                        {state.errors.includes('error_obligorPage3_email') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Row className="question_btn">
                    <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={() => pageData.prevPage('page28', 'obligorPage3')} >{back_btn}</Button></Col>
                    <Col sm></Col>
                    <Col sm></Col>
                    <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={()=>pageData.nextPage('obligorPage3','page29')}  >{next_btn}</Button></Col>
                </Row>
            </Form>
        </>
    )
}