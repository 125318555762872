import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export const page4 = (pageData) => {
        let state = pageData.state
        const { t, i18n, classes } = pageData.props;

        let back_btn = t('common_btn.back')
        let next_btn = t('common_btn.next')
        let error_msg_detail = t('error_msg.error_msg_detail')
        let label_page_title = t('question.page_4.page_title')
        let label_current_citizenship = t('question.page_4.current_citizenship')
        let label_orignal_citizenship = t('question.page_4.orignal_citizenship')


        if(state.page4.currentCitizenship && !state.page4.currentCitizenship.label)state.page4.currentCitizenship = JSON.parse(state.page4.currentCitizenship)
        if(state.page4.orignalCitizenship && !state.page4.orignalCitizenship.label)state.page4.orignalCitizenship = JSON.parse(state.page4.orignalCitizenship)


        return(
            <>
                <p className="question-title">{label_page_title}</p>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>{label_current_citizenship}</Form.Label>
                        <Select
                            value={state.page4.currentCitizenship}
                            onChange={pageData.handleChangeSelect('page4','currentCitizenship')}
                            options={state.countryListOptions}
                        />
                        {state.errors.includes('error_page4_currentCitizenship') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_orignal_citizenship}</Form.Label>
                        <Select
                            value={state.page4.orignalCitizenship}
                            onChange={pageData.handleChangeSelect('page4','orignalCitizenship')}
                            options={state.countryListOptions}
                        />
                        {state.errors.includes('error_page4_orignalCitizenship') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                        </Form.Group>
                    </Form.Row>
                    <Row className="question_btn">
                        <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={()=>pageData.prevPage('page3','page4')} >{back_btn}</Button></Col>
                        <Col sm></Col>
                        <Col sm></Col>
                        <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={()=>pageData.nextPage('page4','page5')} >{next_btn}</Button></Col>
                    </Row>
                    </Form>
            </>
        )
    }