import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';

import order_failed_image from '../../assets/img/order_failed.gif'

export const Page31 = (pageData) => {
        let state = pageData.state
        const { t, i18n, classes } = pageData.props;

        let back_btn = t('common_btn.back')
        let retry_btn = t('common_btn.retry')


        let label_page_title = t('question.page_31.page_title')
        return(
            <>


                <img style={{width:"300px"}} src={order_failed_image}></img>
                <p className="question-title">{label_page_title}</p> 

            
                <Row className="question_btn">
                        <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={()=>pageData.prevPage('page30','page31')} >{back_btn}</Button></Col>
                        <Col sm></Col>
                        <Col sm></Col>
                        <Col sm><Button variant="primary" size="lg" block className="next_btn"  >{retry_btn}</Button></Col>
                </Row>
            </>
        )
    }