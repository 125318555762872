import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export const page5 = (pageData) => {
        let state = pageData.state
        const { t, i18n, classes } = pageData.props;

        let back_btn = t('common_btn.back')
        let next_btn = t('common_btn.next')
        let yes_btn = t('common_btn.yes')
        let no_btn = t('common_btn.no')
        let label_page_title = t('question.page_5.page_title')
        let label_question_title = t('question.page_5.question_title')
        let label_question_description = t('question.page_5.description')

        return(
            <>
                <p className="question-title">{label_page_title}</p>
                <p className="sub-question-title">{label_question_title}</p>
                <Form>
                    <Row className="question_btn">
                        <Col sm></Col>
                        <Col sm>
                            <Button 
                                variant="primary" size="lg" block className="next_btn" 
                                onClick={pageData.handleChangeAnswerChoice('page5','answer','yes','page61')} >{yes_btn}
                            </Button>
                        </Col>
                        <Col sm>
                            <Button 
                                variant="outline-primary" size="lg" block className="back_btn" 
                                onClick={pageData.handleChangeAnswerChoice('page5','answer','no','page6')} >{no_btn}
                            </Button>
                        </Col>
                        <Col sm></Col>
                    </Row>

                    <div style={{marginTop:"40px"}}>
                        <p  style={{ cursor:"pointer",
                                    fontFamily: "inherit",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    fontSize: "1.25rem",
                                    lineHeight: "1.5",
                                    textAlign: "center",
                                    color: "#007bff"}}
                            onClick={()=>pageData.prevPage('page4','page5')}
                        >{back_btn}</p>
                    </div>
                    {/* <Row className="question_btn">
                        <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={()=>pageData.prevPage('page4','page5')} >{back_btn}</Button></Col>

                    </Row> */}
                    <p className="question-description">{label_question_description}</p>
                    {/* <Row className="question_btn">
                        <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={()=>pageData.prevPage('page4','page5')} >{back_btn}</Button></Col>
                        <Col sm></Col>
                        <Col sm></Col>
                        <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={()=>pageData.nextPage('page5','page6')} >{next_btn}</Button></Col>
                    </Row> */}
                </Form>
            </>
        )
    }