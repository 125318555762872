import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';

export const page26 = (pageData) => {
        let state = pageData.state
        const { t, i18n, classes } = pageData.props;

        let back_btn = t('common_btn.back')
        let next_btn = t('common_btn.next')
        let error_msg_detail = t('error_msg.error_msg_detail')
        let label_page_title = t('question.page_26.page_title')
        let label_by_the_applicant_himself = t('question.page_26.by_the_applicant_himself')
        let label_by_third_parties = t('question.page_26.by_third_parties')
        let label_by_invitings_party = t('question.page_26.by_invitings_party')
        let label_by_others_deviating_obligor = t('question.page_26.by_others_deviating_obligor')
        let label_question_title = t('question.page_26.question_title')

        return(
            <>
                <p className="question-title">{label_page_title}</p> 
                <p className="sub-question-title">{label_question_title}</p>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="validationFormik1" style={{ textAlign : "start" }}>
                        <Form.Check
                            required
                            name="terms"
                            label={label_by_the_applicant_himself}
                            onChange={pageData.handleChangeCheckbox('page26','applicant')} 
                            checked={state.page26.applicant} 
                            id="validationFormik1"
                        />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="validationFormik2" style={{ textAlign : "start"}}>
                        <Form.Check
                            required
                            name="terms"
                            label={label_by_third_parties}
                            onChange={pageData.handleChangeCheckbox('page26','thirdParty')} 
                            checked={state.page26.thirdParty} 
                            id="validationFormik2"
                            style={{textSize:"5px"}}
                        />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="validationFormik3" style={{ textAlign : "start" }}>
                        <Form.Check
                            required
                            name="terms"
                            label={label_by_invitings_party}
                            onChange={pageData.handleChangeCheckbox('page26','invitingParty')} 
                            checked={state.page26.invitingParty} 
                            id="validationFormik3"
                        />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="validationFormik4" style={{ textAlign : "start" }}>
                        <Form.Check
                            required
                            name="terms"
                            label={label_by_others_deviating_obligor}
                            onChange={pageData.handleChangeCheckbox('page26','other')} 
                            checked={state.page26.other} 
                            id="validationFormik4"
                        />
                        </Form.Group>
                    </Form.Row>
                    <Row className="question_btn">
                        <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={()=>pageData.prevPage('page25','page26')} >{back_btn}</Button></Col>
                        <Col sm></Col>
                        <Col sm></Col>
                        <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={()=>pageData.nextPageForCheckbox('page26','page27')} >{next_btn}</Button></Col>
                    </Row>
                </Form>
            </>
        )
    }