
import 'date-fns';
import React, { PureComponent, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import 'react-calendar/dist/Calendar.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css"

function ValidateUser(props) {
 
  console.log("VALIDATE USER")

  useEffect(()=>{

    console.log("VALIDATE USER USE-EFFECT")
   
    let url_pathname = window.location.pathname

    let token = url_pathname.split('/validate-user/')[1]

    props.validateUser(token)

}, [])   


     return (
         <>
           
       </>
     )
};

export default ValidateUser;