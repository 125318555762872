import React, { useState, useEffect } from 'react';
import { Navbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { signOut } from '../redux/actions/index'
import uk_flag from '../assets/img/uk_flag1.png'
import logo from '../assets/img/logo_placeholder.png'

const Navigation = (props) => {

    const { onSignOut, question, logout, logout_question } = props;
    const [ showBtn, setShowBtn ] =  useState(false);
    const [ lang, setLang ] =  useState("en");

    useEffect(() => {
      
        if(!logout || !logout_question){

            setShowBtn(true)
        }else{
            setShowBtn(false)
        }
    });
 
    useEffect(() => {
        let path = window.location.pathname

        const currentLang = path.substring(0,3);
        setLang(currentLang);
    }, []);


    const changeLanguage = () => {

        let path = window.location.pathname

        const currentLang = path.substring(0,3);

        const restUrl = path.substring(3,path.length) + window.location.search

        let newUrl = "";

        switch(currentLang){

            case "/de":
                newUrl += "/en"+ restUrl
                break;
            case "/en":
                newUrl += "/de"+ restUrl
                break;
        }

        window.open(newUrl,"_self")

    }

    return (
        <Navbar  bg="white" className="navbar d-block p-3 bg-white fixed-top">
            <div className="row">
            <Navbar.Brand href={lang} style={{marginTop: "5px"}} className=" g-4 xs-12">
              <img
                  alt=""
                  src={logo}
                  width="30"
                  height="30"
                  className="d-inline-block align-top"
              />{' '}
                        &nbsp;<span className="navbar_logo" >Abfrage Software</span> 
          </Navbar.Brand>
              <Navbar.Collapse className="navbar_text justify-content-end lg-8 xs-12">
                    {
                         showBtn && <button onClick={() => onSignOut()} type="button" className="signout-btn btn btn-primary ml-2">{"Sign Out"}</button>
                    }
              </Navbar.Collapse>
            </div>
      </Navbar>
  );
};

const mapStateToProps = state => {

    return {
        question: state.question.question.question,
        logout: state.auth.logout,
        logout_question: state.question.logout
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSignOut: () => dispatch(signOut()),
    };
};

export default
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Navigation);