import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';

export const page28 = (pageData) => {
        let state = pageData.state
        const { t, i18n, classes } = pageData.props;

        let finish_btn = t('common_btn.finish')
        let back_btn = t('common_btn.back')
        let obligor_1 = t('question.page_28.obligor_1')
        let obligor_2 = t('question.page_28.obligor_2')
        let obligor_3 = t('question.page_28.obligor_3')
        let label_page_title = t('question.page_28.page_title')
        let label_question_title = t('question.page_28.question_title')
        return(
            <>
                <p className="question-title">{label_page_title}</p> 
                <p className="sub-question-title">{label_question_title}</p>
                <Form>
                    <Form.Row>
                        <Col sm>
                            <button type="button" onClick={()=>pageData.handleChangeObligorPage('obligorPage1')} class="btn btn-outline-primary choice-button mb-4">
                                {obligor_1}
                            </button>
                        </Col>
                        <Col sm>
                            <button type="button" onClick={()=>pageData.handleChangeObligorPage('obligorPage2')} class="btn btn-outline-primary choice-button mb-4">
                                {obligor_2}
                            </button>
                        </Col>
                        <Col sm>
                            <button type="button" onClick={()=>pageData.handleChangeObligorPage('obligorPage3')} class="btn btn-outline-primary choice-button mb-4">
                                {obligor_3}
                            </button>
                        </Col> 
                    </Form.Row>
                </Form>
                <Row className="question_btn">
                    <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={()=>pageData.prevPage('page27','page28')} >{back_btn}</Button></Col>
                    <Col sm></Col>
                    <Col sm></Col>
                    <Col sm></Col>
                </Row>
            </>
        )
    }