import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const page19 = (pageData) => {
        let state = pageData.state
        const { t, i18n, classes } = pageData.props;
        let currentDate = new Date()

        let back_btn = t('common_btn.back')
        let next_btn = t('common_btn.next')
        let error_msg_detail = t('error_msg.error_msg_detail')
        let label_page_title = t('question.page_19.page_title')
        let label_planned_arrival_date_in_the_schengen_area = t('question.page_19.planned_arrival_date_in_the_schengen_area')
        let label_planned_departure_date_from_the_schengen_area = t('question.page_19.planned_departure_date_from_the_schengen_area')
        let label_duration_of_the_planned_stay_of_transit = t('question.page_19.duration_of_the_planned_stay_of_transit')

        if(state.page19.arrivalDate)state.page19.arrivalDate = new Date(state.page19.arrivalDate)
        if(state.page19.departureDate)state.page19.departureDate = new Date(state.page19.departureDate)

        return(
            <>
                <p className="question-title">{label_page_title}</p>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>{label_planned_arrival_date_in_the_schengen_area}</Form.Label>
                            <DatePicker
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                maxDate={currentDate}
                                selected={state.page19.arrivalDate}
                                onSelect={pageData.handleChangeCalendar('page19', 'arrivalDate')} //when day is clicked
                                onChange={pageData.handleChangeCalendar('page19', 'arrivalDate')} //only when value has changed
                            />
                            {state.errors.includes('error_page19_arrivalDate') &&
                                <p className="error_class">{error_msg_detail}</p>
                            }
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>{label_planned_departure_date_from_the_schengen_area}</Form.Label>
                            <DatePicker
                                className="form-control"
                                dateFormat="dd/MM/yyyy"
                                maxDate={currentDate}
                                selected={state.page19.departureDate}
                                onSelect={pageData.handleChangeCalendar('page19', 'departureDate')} //when day is clicked
                                onChange={pageData.handleChangeCalendar('page19', 'departureDate')} //only when value has changed
                            />
                            {state.errors.includes('error_page19_departureDate') &&
                                <p className="error_class">{error_msg_detail}</p>
                            }
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>{label_duration_of_the_planned_stay_of_transit}</Form.Label>
                            <Form.Control 
                                type="text" 
                                onChange={pageData.handleChange('page19','duration')} 
                                value={state.page19.duration} 
                            />
                            {state.errors.includes('error_page19_duration') &&
                                <p className="error_class">{error_msg_detail}</p>
                            }
                        </Form.Group>
                    </Form.Row>
                    <Row className="question_btn">
                        <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={()=>pageData.prevPage('page18','page19')} >{back_btn}</Button></Col>
                        <Col sm></Col>
                        <Col sm></Col>
                        <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={()=>pageData.nextPage('page19','page20')} >{next_btn}</Button></Col>
                    </Row>
                </Form>
            </>
        )
    }