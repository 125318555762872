import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export const page14 = (pageData) => {
        let state = pageData.state
        const { t, i18n, classes } = pageData.props;

        let back_btn = t('common_btn.back')
        let next_btn = t('common_btn.next')
        let error_msg_detail = t('error_msg.error_msg_detail')
        let label_page_title = t('question.page_14.page_title')
        let label_issuing_state = t('question.page_14.issuing_state')
        let label_issued_by = t('question.page_14.issued_by')
        let label_issued_in = t('question.page_14.issued_in')

        if(state.page14.issuingState && !state.page14.issuingState.label)state.page14.issuingState = JSON.parse(state.page14.issuingState)


        return(
            <>
                <p className="question-title">{label_page_title}</p>
                <Form>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>{label_issuing_state}</Form.Label>
                    <Select
                        value={state.page14.issuingState}
                        onChange={pageData.handleChangeSelect('page14','issuingState')}
                        options={state.countryListOptions}
                    />
                    {state.errors.includes('error_page14_issuingState') &&
                        <p className="error_class">{error_msg_detail}</p>
                    }
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Label>{label_issued_by}</Form.Label>
                    <Form.Control 
                        type="text" 
                        onChange={pageData.handleChange('page14','issuedBy')} 
                        value={state.page14.issuedBy} 
                    />
                    {state.errors.includes('error_page14_issuedBy') &&
                        <p className="error_class">{error_msg_detail}</p>
                    }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>{label_issued_in}</Form.Label>
                    <Form.Control 
                        type="text" 
                        onChange={pageData.handleChange('page14','issuedIn')} 
                        value={state.page14.issuedIn} 
                    />
                    {state.errors.includes('error_page14_issuedIn') &&
                        <p className="error_class">{error_msg_detail}</p>
                    }
                    </Form.Group>
                </Form.Row>
                <Row className="question_btn">
                    <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={()=>pageData.prevPage('page13','page14')} >{back_btn}</Button></Col>
                    <Col sm></Col>
                    <Col sm></Col>
                    <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={()=>pageData.nextPage('page14','page15')} >{next_btn}</Button></Col>
                </Row>
            </Form>
        </>
        )
    }