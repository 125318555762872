import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';

export const page9 = (pageData) => {
        let state = pageData.state
        const { t, i18n, classes } = pageData.props;

        let back_btn = t('common_btn.back')
        let next_btn = t('common_btn.next')
        let error_msg_detail = t('error_msg.error_msg_detail')
        let label_page_title = t('question.page_9.page_title')
        let label_telephone = t('question.page_9.telephone')
        let label_email = t('question.page_9.email')

        return(
            <>
            <p className="question-title">{label_page_title}</p>
            <Form>
                <Form.Row>
                    <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>{label_telephone}</Form.Label>
                        <Form.Control 
                            type="text" 
                            onChange={pageData.handleChange('page9','telephone')} 
                            value={state.page9.telephone} 
                        />
                        {state.errors.includes('error_page9_telephone') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>{label_email}</Form.Label>
                        <Form.Control 
                            type="text" 
                            onChange={pageData.handleChange('page9','email')} 
                            value={state.page9.email} 
                        />
                        {state.errors.includes('error_page9_email') &&
                            <p className="error_class">{error_msg_detail}</p>
                        }
                    </Form.Group>
                </Form.Row>
                <Row className="question_btn">
                    <Col sm><Button variant="outline-primary" size="lg" block className="back_btn" onClick={()=>pageData.prevPage('page8','page9')} >{back_btn}</Button></Col>
                    <Col sm></Col>
                    <Col sm></Col>
                    <Col sm><Button variant="primary" size="lg" block className="next_btn" onClick={()=>pageData.nextPage('page9','page10')} >{next_btn}</Button></Col>
                </Row>
            </Form>
        </>
        )
    }